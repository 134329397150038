import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const PaddedCard = ({ className, children }) => (<Card className={`shadow-sm ${className}`}>{children}</Card>)
const UniformPaddedCard = styled(PaddedCard)`
    height: 100%;

    &:hover {
        cursor: pointer;
    }
`

const ProjectItem = ({ img, title, desc, tags, route = "/" }) => {
    const navigate = useNavigate()

    return (
        <motion.div whileHover={{ scale: 1.01 }} onClick={() => { navigate(route) }}>
            <UniformPaddedCard>
                <Card.Img alt={title} src={img} className="d-block w-100 rounded" />
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{desc}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {tags.map(tag => <Badge key={title + '-' + tag}>{tag}</Badge>)}
                </Card.Footer>
            </UniformPaddedCard>
        </motion.div>
    )
}

export default ProjectItem;