import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import { CodeBlock, nord } from "react-code-blocks";
import Accordion from "react-bootstrap/Accordion";
import eq5 from "../../img/bg/eq5.jpeg";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const VcSigma = () => {
  const ease = [0.08, 0.37, 0.45, 0.89];

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <div>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={eq5}>
            <Header>
              Showing that the VC-dimension is {`\\(\\Sigma_3^P\\)`}-complete
            </Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p>
                The <strong>VC (Vapnik–Chervonenkis) dimension</strong> is a
                measure of complexity of a set of functions that can be learned
                by a simple binary classification algorithm. To define it more
                formally, we need the concept of <em>shattering</em>.
              </p>
              <p>
                Given a collection {`\\(S = \\{S_1, ..., S_M\\}\\)`} of subsets
                of a finite set {`\\(U\\)`}, the VC-dimension of {`\\(S\\)`} is
                the size of the largest set {`\\(X \\subseteq U\\)`} such that
                for every {`\\(X' \\subseteq X\\)`}, there is an {`\\(i\\)`} for
                which {`\\(S_i \\cap X = X'\\)`}. For this {`\\(i\\)`}, we can
                write that {`\\(X\\)`} is shattered by {`\\(S\\)`}. So, a
                Boolean circuit {`\\(C\\)`} that computes a function{" "}
                {`\\(f:\\{0, 1\\}^m \\times \\{0, 1\\}^n \\rightarrow \\{0, 1\\}\\)`}{" "}
                succinctly represents a collection {`\\(S\\)`} of {`\\(2^m\\)`}{" "}
                subsets of {`\\(U = \\{0, 1\\}^n\\)`} as follows: the set{" "}
                {`\\(S_i\\)`} consists of exactly those elements {`\\(x\\)`} for
                which {`\\(C(i, x) = 𝟏\\)`}.
              </p>
              <p>
                Using this notion, the language <strong>VC-DIMENSION</strong> is
                the set of pairs {`\\((C, k)\\)`} for which {`\\(C\\)`}{" "}
                represents a collection of subsets {`\\(S\\)`} whose VC
                dimension is at least {`\\(k\\)`}. The purpose of this blog post
                is to show that this language has an intricate relationship with
                the polynomial hierarchy in computational complexity.
              </p>
              <p>
                To explain this better, I’m going to introduce some notation:
                Let {`\\(\\text{P}^A\\)`} be the set of problems solvable in
                poly-time with a TM that uses an oracle for a problem in{" "}
                {`\\(A\\)`}. Let {`\\(\\text{NP}^A\\)`} be the set of problems
                solvable in nondeterministic poly-time with a TM that uses an
                oracle for a problem in {`\\(A\\)`}. Let{" "}
                {`\\(\\text{coNP}^A\\)`} be the set of problems solvable in
                co-nondeterministic poly-time with a TM that uses an oracle for
                a problem in {`\\(A\\)`}.
              </p>
              <p>
                Then, define {`\\(\\Delta_0^P := \\Sigma_0^P := \\Pi_0^P:=P\\)`}
                , where {`\\(P\\)`} is the set of problems that can be solved in
                poly-time. Then, for all {`\\(i \\geq 0\\)`}, define:
              </p>
              <p>{`$$\\Delta_{i+1}^P:=\\text{P}^{\\Sigma_i^P}$$`}</p>
              <p>{`$$\\Sigma_{i+1}^P:=\\text{NP}^{\\Sigma_i^P}$$`}</p>
              <p>{`$$\\Pi_{i+1}^P:=\\text{coNP}^{\\Sigma_i^P}$$`}</p>
              <p>
                The result that I want to show here is that the VC-dimension is
                in {`\\(\\Sigma_3^P\\)`}, and that is the strongest form of
                problem in {`\\(\\Sigma_3^P\\)`}. So, any solution that can
                compute the VC-dimension can be used to compute any other
                problem in {`\\(\\Sigma_3^P\\)`}. The complexity-theoretic word
                for this phenomenon is <em>completenesss</em>. That is to say,
                the V-dimension is {`\\(\\Sigma_3^P\\)`}-complete.
              </p>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 1: The size of the largest possible set {`\\(X\\)`}{" "}
                      shattered by a collection of {`\\(2^m\\)`} subsets is{" "}
                      {`\\(m\\)`}.
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      By definition, {`\\(X\\)`} being shattered by a collection
                      of sets {`\\(S\\)`} means that
                    </p>

                    <p>{`$$\\forall X' \\subseteq X, \\exists i s.t. S_𝑖 \\cap X = X'$$`}</p>

                    <p>
                      This is the same thing as writing that {`\\(X\\)`} is
                      shattered by a collection of sets {`\\(C\\)`} if and only
                      if:
                    </p>

                    <p>{`$$P(𝐴) = \\{X ∩ S_i | S_i \\in C\\}$$`}</p>

                    <p>
                      However, since a set of size {`\\(m\\)`} has a power set
                      of size {`\\(2^m\\)`}, no set of size {`\\(> m\\)`} will
                      have {`\\(2^m\\)`} possible subsets (a set of size{" "}
                      {`\\(m + 1\\)`} will have a power set of size{" "}
                      {`\\(2^{m+1} = 2 \\cdot 2^m > 2^m\\)`}). Therefore, a set{" "}
                      {`\\(X\\)`} that is shattered by a collection of{" "}
                      {`\\(2^m\\)`} subsets will have a size atmost {`\\(m\\)`}.
                      Hence, the largest possible {`\\(X\\)`} will have size{" "}
                      {`\\(m\\)`}.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 2: Arguing that the VC-dimension is in{" "}
                      {`\\(\\Sigma_3^P\\)`}.
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      A Boolean circuit {`\\(C\\)`} that computes a function{" "}
                      {`\\(f: \\{0, 1\\}^m \\times \\{0, 1\\}^n \\to \\{0, 1\\}\\)`}{" "}
                      succinctly represents a collection {`\\(s\\)`} of{" "}
                      {`\\(2^m\\)`} subsets of {`\\(U = \\{0, 1\\}^n\\)`}.
                      However, if the Boolean circuit represents {`\\(2^m\\)`}{" "}
                      possible subsets, then (by lemma 1) the VC-DIMENSION of{" "}
                      {`\\(C\\)`} is {`\\(m\\)`}. Note then that by definition:
                    </p>

                    <p>{`$$\\Sigma_3^P = \\text{NP}^{\\Sigma_2^P} = \\text{NP}^{\\text{NP}^{\\Sigma_1^P}} = \\text{NP}^{\\text{NP}^{\\text{NP}}}$$`}</p>

                    <p>
                      Therefore, we can now define instances of the 𝑉𝐶-dimension
                      decision problem by phrasing the question as whether
                      whether there is a “
                      {`\\(\\{(𝐶, 𝑚) \\text{ s.t. } VC(C) \\geq m\\}\\)`}”?”
                      where {`\\(C\\)`} is the collection of circuits which
                      succinctly represent the collection of subsets {`\\(S\\)`}
                      .
                    </p>

                    <p>
                      The “yes” instance (using the{" "}
                      {`\\(\\mathrm{NP}^{\\mathrm{NP}^{\\mathrm{NP}}}\\)`}{" "}
                      notation) of the VC-dimension problem would then be:
                    </p>

                    <p>{`$$\\text{VC−DIMENSION}=\\{(C,m): \\exists X, X\\geq m \\text{ s.t. } \\forall X' \\subseteq X,\\exists i \\text{ s.t. } S_i \\cap X = X'\\}$$`}</p>

                    <p>{`$$\\text{VC−DIMENSION}=\\{(C,m):\\exists X, |X|\\geq m \\text{ s.t. } \\forall X' \\subseteq X, \\exists i\\text{ s.t. }\\forall x\\in X',C(i,x)=1\\}$$`}</p>

                    <p>
                      Since every certificate would also need a certificate
                      which would in turn need a proof of membership (just by
                      the nature of this representation), where{" "}
                      {`\\(i, X, X'\\)`} are polynomially large in {`\\(m\\)`},
                      the nondeterminism can just act on every subset knowing
                      that each subset would be polynomially large since{" "}
                      {`\\(\\mathrm{poly}(\\mathrm{poly}(\\mathrm{poly}(m))) \\in \\mathrm{poly}(m)\\)`}
                      .
                    </p>

                    <p>
                      Therefore, we can decide a language in polynomial time
                      since atleast one of the (polynomially long) computation
                      paths would lead to an “ACCEPT” state if it is a “yes”
                      instance and none of the (polynomially long) computation
                      paths would lead to a “REJECT” state if it is a “no”
                      instance.
                    </p>

                    <p>Therefore, we have that:</p>

                    <p>{`$$\\text{VC-DIMENSION} \\in \\Sigma_3^P$$`}</p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 3: Arguing that the VC-dimension is{" "}
                      {`\\(\\Sigma_3^P\\)`}-complete through a reduction from{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`}.
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Given an instance of VC-DIMENSION:</p>

                    <p>{`$$\\text{VC−DIMENSION}=\\{(C,m):\\exists X,|X|\\geq m\\text{ s.t. }\\forall X' \\subseteq 𝑋,\\exists 𝑖\\text{ s.t. }\\forall x \\in X',C(i,x)=1\\}$$`}</p>

                    <p>
                      We can show that it is {`\\(\\Sigma_3^P\\)`}-complete by
                      reducing from an instance of {`\\(\\mathrm{QSAT}_3\\)`}{" "}
                      which is known to be {`\\(\\Sigma_3^P\\)`}-complete.
                      Therefore, let an arbitrary instance of{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} be:
                    </p>

                    <p>{`$$\\exists x_1 \\forall x_2 \\exists x_3 \\text{ s.t. } \\varphi(x_1,x_2,x_3)=1$$`}</p>

                    <p>
                      We then define the universe {`\\(U\\)`} as the set{" "}
                      {`\\(\\{0, 1\\}^\\ell \\times \\{1, 2, 3, ... \\ell\\}\\)`}{" "}
                      s.t. {`\\(\\forall x_1, |x_1| = \\ell\\)`}, we define the
                      following subset:{" "}
                      {`$$U_{x_1} = \\{x_1\\} \\times \\{1,2,3,...\\ell\\}$$`}
                    </p>

                    <p>
                      Using this, we define the collection of sets, {`\\(S\\)`},
                      succinctly represented by circuit {`\\(C\\)`} (note that
                      the circuit would have to maintain this invariant{" "}
                      {`\\(\\forall x_1, x_2, x_3\\)`}, as:
                    </p>

                    <ol type="1">
                      <li>
                        <p>
                          If {`\\(\\varphi(x_1, x_2, x_3)=1\\)`}, then{" "}
                          {`\\(𝑆(x_1, x_2, x_3) = \\{x_1\\}\\times\\{1,2,3,...\\ell\\},|x_1|=l\\)`}
                        </p>
                      </li>
                      <li>
                        <p>
                          If {`\\(\\varphi(x_1, x_2, x_3) = 0\\)`}, then{" "}
                          {`\\(𝑆(x_1, x_2, x_3) = \\phi\\)`}, where{" "}
                          {`\\(\\phi\\)`} is the empty set (note that we don’t
                          need to address this case here since{" "}
                          {`\\(\\varphi(x_1,x_2,x_3) = 0\\)`} should really just
                          do nothing, hence the empty set will suffice), where
                          the name of each set is {`\\((x_1, x_2, x_3)\\)`}.
                        </p>
                      </li>
                    </ol>

                    <p>We now define the reduction function {`\\(f\\)`}:</p>

                    <p>
                      <strong>Inputs</strong>: A set named{" "}
                      {`\\((x_1, x_2, x_3)\\)`} and an element,{" "}
                      {`\\(a = (x'_1, x_2)\\)`} of a potential subset{" "}
                      {`\\(U_{x_1}\\)`}.
                    </p>

                    <p>
                      <strong>Function</strong>: The isomorphism between the
                      VC-DIMENSION instance {`\\((C,\\ell)\\)`} and{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} instance{" "}
                      {`\\(\\varphi(x_1, x_2, x_3)\\)`}.
                    </p>

                    <p>
                      <strong>Deciding Procedure</strong>: We return “ACCEPT” if{" "}
                      {`\\(a \\in S(x_1, x_2, x_3)\\)`} and “NO” if{" "}
                      {`\\(a \\notin S(x_1, x_2, x_3)\\)`}.
                    </p>

                    <p>Therefore, we do the following:</p>

                    <p>
                      If{" "}
                      {`\\((\\varphi(x_1, x_2, x_3) = 1) \\wedge (a \\in (x_1, \\ell))\\)`}
                      , return “ACCEPT Else, return “REJECT”.
                    </p>

                    <p>
                      Finally, the onus of the proof is to show that “yes”
                      instances of {`\\(\\mathrm{QSAT}_3\\)`} maps to “yes”
                      instances of the VC-DIMENSION decision problem and vice
                      versa, and that “no” instances of{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} maps to “no” instances of the
                      VC-DIMENSION decision problem and vice versa:
                    </p>

                    <p>
                      To show that “yes” maps to “yes”: To show that “yes”{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} instances map to “yes”
                      VC-DIMENSION instances:
                    </p>

                    <p>
                      Given a “yes” instance of {`\\(\\mathrm{QSAT}_3\\)`},{" "}
                      {`\\(\\exists x_1 \\forall x_2 \\exists x_3 \\text{ s.t. } \\varphi(x_1, x_2 , x_3 ) = 1\\)`}
                      , we know that the set{" "}
                      {`\\(S(x_1 , x_2 , x_3 ) = \\{x_1\\}\\times x_2\\)`}.
                    </p>

                    <p>Recall that the initial subset was:</p>

                    <p>{`$$U_{𝑥_1} =\\{x_1\\}\\times\\{1,2,3,...\\ell\\}$$`}</p>

                    <p>
                      However, since{" "}
                      {`\\(S(x_1, x_2, x_3) = \\{x_1\\} \\times x_2\\)`} and{" "}
                      {`\\(\\varphi(x_1, x_2, x_3) = 1\\)`}, the VC dimension of{" "}
                      {`\\(S\\)`} would be {`\\(\\geq x_2 = \\ell\\)`}. Hence,
                      the VC dimension of {`\\(S\\)`} that is represented by
                      circuit {`\\(C\\)`} would be {`\\(\\geq  \\ell\\)`}, which
                      satisfies the decision problem.
                    </p>

                    <p>QED.</p>

                    <p>
                      To show that “yes” VC-DIMENSION instances map to “yes”{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} instances:
                    </p>

                    <p>
                      Given a “yes” instance of VC-DIMENSION {`\\((𝐶, 𝑚)\\)`},
                      then the set {`\\(S\\)`} represented by the circuit{" "}
                      {`\\(C\\)`} has a size atleast {`\\(m\\)`}. Therefore,{" "}
                      {`\\(\\exists X, |X| \\geq  m \\text{ s.t. } S \\text{ shatters } X\\)`}
                      . It follows then by definition of shattering that{" "}
                      {`\\(\\exists  k \\text{ s.t. } X \\in U_k\\)`}. However,
                      this can only happen if{" "}
                      {`\\(\\forall x_2, {x_1} \\times x_2 \\in S\\)`}. This is
                      the same thing as writing:
                    </p>

                    <p>{`$$\\exists x_1 \\forall x_2 \\exists x_3 s.t.\\phi(x_1, x_2, x_3) = 1,$$`}</p>

                    <p>
                      which is a “yes” instance of {`\\(\\mathrm{QSAT}_3\\)`}.
                      QED.
                    </p>

                    <p>
                      To show that “no” maps to “no”, the contrapositive
                      argument holds since a “yes” instance of the VC-DIMENSION
                      problem being reduced to a “yes” instance of{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} implies that “no” instances of{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} can be reduced to “no”
                      instances of VC-DIMENSION, and a “yes” instance of{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} being reduced to a “yes”
                      instance of VC-DIMENSION implies that a “no” instance of
                      VC-DIMENSION can be reduced to a “no” instance of{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`}.
                    </p>

                    <p>
                      Since we have shown that “yes” maps to “yes” and “no” maps
                      to “no”, we have that {`\\(\\exists\\)`} a reduction
                      function from instances of {`\\(\\mathrm{QSAT}_3\\)`} to
                      instances of the VC-DIMENSION problem and that{" "}
                      {`\\(\\exists\\)`} a reduction function from instances of
                      the VC-DIMENSION problem to instances of{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`}. Additionally, since{" "}
                      {`\\(\\mathrm{QSAT}_3\\)`} is known to be{" "}
                      {`\\(\\Sigma_3^P\\)`}-complete, it follows then that
                      VC-DIMENSION is also {`\\(\\Sigma_3^P\\)`}-complete.
                    </p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </div>
  );
};

export default VcSigma;
