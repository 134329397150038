import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import {
  useLocation, useRoutes, createBrowserRouter, RouterProvider
} from "react-router-dom";
import Blog from "./pages/test_blog";
import Extracurriculars from "./pages/Extracurriculars";
import Home from "./pages/Home";
import Publications from "./pages/Publications";
import CV from "./pages/CV";
import AboutMe from "./pages/AboutMe";
import Teaching from "./pages/Teaching";
import { MathJaxContext } from "better-react-mathjax";
import { BLOG_ROUTES } from './constants';

const routes = [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/about-me",
    element: <AboutMe />
  },
  {
    path: "/publications",
    element: <Publications />
  },
  {
    path: "/teaching",
    element: <Teaching />
  },
  {
    path: "/blog",
    element: <Blog />
  },
  {
    path: "/cv",
    element: <CV />
  },
  ...BLOG_ROUTES
]

const router = createBrowserRouter(
  routes
);

function App() {
  useEffect(() => {
    document.title = 'Emile Timothy'
  }, [])

  return (
    <AnimatePresence>
      <MathJaxContext>
        <RouterProvider router={router} />
      </MathJaxContext>
    </AnimatePresence>
  )
}

export default App;
