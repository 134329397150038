import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import { CodeBlock, nord } from "react-code-blocks";
import Accordion from "react-bootstrap/Accordion";
import sipser_lautemann from "../../img/bg/sipser_lautemann.webp";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const SipserLautemann = () => {
  const ease = [0.08, 0.37, 0.45, 0.89];

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={sipser_lautemann}>
            <Header>
              Proving the Sipser-Lautemann theorem:{" "}
              {`\\(\\mathrm{BPP} \\subseteq \\Sigma_2^\\mathrm P \\cap \\Pi_2^\\mathrm P\\)`}
            </Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p className="hidden">{`$$\\DeclareMathOperator*{\\EE}{\\mathbb{E}}$$`}</p>
              <p>
                Happy Sunday! Today, I’m going to share another really cool
                result involving some substructures of the polynomial hierarchy.
                As usual, I’m going to start by giving my regular schpiel about
                what the polynomial hierarchy is.
              </p>
              <p>
                The classes NP and co-NP are near the bottom of the “polynomial
                time hierarachy,” which consists of the complexity classes{" "}
                {`\\(\\Sigma_k^p, \\Delta_k^p, \\Pi_k^p\\)`} for{" "}
                {`\\(k\\geq 0\\)`} defined as follows: We say that{" "}
                {`\\(L \\in \\Sigma_k^p\\)`} if there is a polynomial time
                deterministic Turing machine {`\\(A\\)`} and a constant{" "}
                {`\\(c<\\infty\\)`} such that the following holds, where all{" "}
                {`\\(|y_i| \\leq |x|^c\\)`} (here {`\\(|\\cdot|\\)`} indicates
                the length of a binary string):
              </p>
              <p>{`$$x \\in L \\iff \\exists y_1 \\forall y_2 \\exists y_3 \\forall y_4 \\cdots \\exists y_{k-1} \\forall y_k: A(x, y_1, \\cdots, y_k) = 1 \\text{ (k even)}$$`}</p>
              <p>{`$$x \\in L \\iff \\exists y_1 \\forall y_2 \\exists y_3 \\forall y_4 \\cdots \\forall y_{k-1} \\exists y_k: A(x, y_1, \\cdots, y_k) = 1 \\text{(k odd)}$$`}</p>
              <p>
                And we define{" "}
                {`\\(\\Pi_p^k = \\mathrm{co}\\text{-}\\Sigma_p^k\\)`} (which by
                de Morgan just means that you start the above lines with a{" "}
                {`\\(\\forall\\)`}. Thus, from the definitions:
              </p>
              <ol type="1">
                <li>
                  {`\\(\\Delta_0^P := \\Sigma_0^P := \\Pi_0^P:=\\mathrm P\\)`},
                  where {`\\(\\mathrm P\\)`} is the set of problems that can be
                  solved in poly-time.
                </li>
                <li>{`\\(\\Delta_{i+1}^P:=\\text{P}^{\\Sigma_i^P}, \\forall i\\geq 0\\)`}</li>
                <li>{`\\(\\Sigma_{i+1}^P:=\\text{NP}^{\\Sigma_i^P}, \\forall i\\geq 0\\)`}</li>
                <li>{`\\(\\Pi_{i+1}^P:=\\text{coNP}^{\\Sigma_i^P}, \\forall i\\geq 0\\)`}</li>
              </ol>
              <p>Furthermore, we have the relation that:</p>
              <p>{`$$ \\Sigma_i^P \\cup \\Pi_i^P \\subseteq \\Sigma_{i+1}^P \\cap \\Pi_{i+1}^P$$`}</p>
              <p>The polynomial-time hierarchy is defined as:</p>
              <p>{`$$\\text{PH} = \\bigcup_{i\\in\\mathbb{N}} \\{\\Delta_i^P, \\Sigma_i^P, \\Pi_i^P\\}$$`}</p>
              <p>
                The result that I want to show here is that despite we have
                absolutely no idea of how {`\\(\\mathrm{BPP}\\)`} and{" "}
                {`\\(P\\)`} are related to each other and even though we don’t
                know where {`\\(\\mathrm{BPP}\\)`} fits into the polynomial
                hierarchy, that {`\\(\\mathrm{BPP}\\)`} is contained somewhere
                in the intersections of the polynomial hierarchy. Specifically,
                I’m going to show that{" "}
                {`\\(\\mathrm{BPP} \\subseteq \\Sigma_2^P \\cap \\Pi_2^P\\)`}.
              </p>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 1: Exponential Amplifications of the Success
                      Probability of BPP Machines via Chernoff Bounds
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Given a problem {`\\(L\\in\\mathrm{BPP}\\)`}, let{" "}
                      {`\\(A\\)`} be a Turing Machine that decides {`\\(L\\)`}.
                      Then,
                    </p>

                    <p>
                      {`$$\\forall x\\in L, \\Pr(A(x) \\text{ accepts})\\geq \\frac{2}{3}$$`}{" "}
                      {`$$\\forall x\\notin L, \\Pr(A(x) \\text{ rejects})< \\frac{1}{3}$$`}
                    </p>

                    <p>
                      Since {`\\(A\\)`} has success probability greater than{" "}
                      {`\\(\\frac{1}{2}\\)`}, we run {`\\(A(x)\\)`} for{" "}
                      {`\\(T\\)`} times and return the majority output.
                    </p>

                    <p>
                      So, we need to find {`\\(T\\in \\mathrm{poly}(n)\\)`} for
                      which the probability that the majority is wrong is atmost{" "}
                      {`\\(2^{-n}\\)`}. For all {`\\(x\\in L\\)`}, let{" "}
                      {`\\(A(x) = 1\\)`} if {`\\(A \\text{ accepts } x\\)`} and{" "}
                      {`\\(0\\)`} otherwise. Then, define{" "}
                      {`\\(\\{Y_i\\}_{i=1}^T\\)`} where{" "}
                      {`\\(Y_i = 1\\{A_i(x)=1\\}\\)`} is the indicator function
                      that returns {`\\(1\\)`} if {`\\(A(x)=1\\)`} on the{" "}
                      {`\\(i\\)`}’th iteration, and {`\\(0\\)`} otherwise. So,{" "}
                      {`\\(\\Pr(\\text{majority is wrong})=\\Pr(\\sum_{i=1}^T Y_i< T/2)=\\Pr(\\bar{Y}< 1/2)\\)`}
                      , where {`\\(\\bar{Y} = \\frac{1}{T}\\sum_{i=1}^T Y_i\\)`}
                      .
                    </p>

                    <p>
                      Then, by the linearity of expectations:{" "}
                      {`$$\\mathbb{E}[\\bar{Y}] = \\mathbb{E}\\bigg[\\frac{1}{T}\\sum_{i=1}^T Y_i\\bigg] = \\frac{1}{T}\\sum_{i=1}^T \\mathbb{E}[Y_i] = \\frac{1}{T}\\sum_{i=1}^T \\mathbb{E}[1\\{A_i(x) = 1\\}] = \\frac{1}{T}\\sum_{i=1}^T \\Pr(A_i(x)=1) \\geq \\frac{2}{3}$$`}
                    </p>

                    <p>
                      Thus, {`\\(\\mathbb{E}[\\bar{Y}] \\geq \\frac{2}{3}\\)`}.
                      We then recall the lower Chernoff bound which states that
                      for random variables{" "}
                      {`\\(\\{X_{i}\\}_{i\\in\\mathbb{N}}\\)`} with mean{" "}
                      {`\\(\\bar{X}\\)`} and expectation {`\\(\\theta\\)`} that:{" "}
                      {`$$\\Pr[\\bar{X}<(1-\\epsilon)\\theta] < \\exp(-\\theta n\\epsilon^2/2)$$`}
                    </p>

                    <p>Then, using this bound in this setting tells us that:</p>

                    <p>{`$$\\Pr(\\bar{Y}<(1-\\epsilon)\\EE[\\bar{Y}])<\\exp(-\\EE[\\bar{Y}] T\\epsilon^2/2)$$`}</p>

                    <p>
                      Setting {`\\(\\epsilon=\\frac{1}{4}\\)`}, yields that{" "}
                      {`\\(\\Pr(\\bar{Y}<(1-\\epsilon)\\EE[\\bar{Y}])=\\Pr(\\bar{Y}<\\frac{1}{2})\\)`}
                      , which we are trying to bound. So, by the Chernoff bound:
                    </p>

                    <p>{`$$\\Pr(\\bar{Y}<(1-\\epsilon)\\EE[\\bar{Y}]) = \\Pr(\\bar{Y}<1/2) \\stackrel{\\text{Chernoff}}{<}  \\exp(-2T/(3\\cdot 4^2 \\cdot 2)) = \\exp(-T/48)$$`}</p>

                    <p>
                      Then, for a good choice of {`\\(T\\)`} we can reduce the
                      probability of the majority output being wrong to less
                      than {`\\(2^{-n}\\)`}. Taking natural logs on both sides
                      gives:
                    </p>

                    <p>{`$$-T/48 < \\log_e 2^{-n} = \\log_2 2^{-n} / \\log_2 e \\implies -T/48 < -n/\\log_2 e \\implies T>\\frac{48n}{\\log_2 e}$$`}</p>

                    <p>
                      Thus, if we set {`\\(T>\\frac{48n}{\\log_2 e}\\)`}, the
                      probability of error is less than {`\\(2^{-n}\\)`}. Since{" "}
                      {`\\(T > \\frac{48n}{\\log_2 e} \\in O(n)\\)`}, we know
                      that any Turing Machine {`\\(A'\\)`} that queries{" "}
                      {`\\(A\\)`} a linear number of times will still run in
                      polynomial time and use polynomially many random bits
                      (with respect to {`\\(n\\)`}. Therefore, we get that any
                      language {`\\(L\\in \\mathrm{BPP}\\)`} has a Turing
                      Machine {`\\(A'\\)`} for which the probability that it is
                      correct is strictly less than {`\\(2^{-n}\\)`} for inputs{" "}
                      {`\\(x\\in\\{0,1\\}^n\\)`}.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <p>
                Thus, we have shown that we can amplify any{" "}
                {`\\(\\mathrm{BPP}\\)`} algorithm {`\\(A\\)`} into {`\\(A'\\)`}{" "}
                that errs on inputs of length {`\\(n\\)`} with probability{" "}
                {`\\(<2^{-n}\\)`}. Then, suppose {`\\(A'\\)`} uses {`\\(r\\)`}{" "}
                random bits. Let {`\\(Z_x \\subseteq \\{0,1\\}^r\\)`} denote the
                subset on which {`\\(A'\\)`} accepts an input {`\\(x\\)`}. For{" "}
                {`\\(s\\in\\{0,1\\}^r\\)`}, let{" "}
                {`\\(Z_x \\oplus s = \\{z\\oplus s :  z \\in Z_x\\}\\)`} and{" "}
                {`\\(a  =  2\\lceil {r/n}\\rceil\\)`}. I’m going to show that
                for {`\\(r  >  1\\)`} (the {`\\(0\\)`} case is really easy to
                see):
              </p>
              <p>{`$$x  \\in  L  \\iff  \\exists s_1 , \\cdots, s_a \\in \\{0,1\\}^r \\text{ such that } \\bigcup_{i=1}^a (Z_x \\oplus s_i) = \\{0,1\\}^r$$`}</p>
              <p>
                In the forward direction, let{" "}
                {`\\(s_1, ..., s_a  \\in  \\{0,1\\}^r\\)`}, independently and
                identically distributed. If{" "}
                {`\\(\\Pr[\\{0,1\\}^r  = \\bigcup_{i=1}^n (Z_x \\oplus s_i)]  >  0\\)`}
                , then {`\\(\\exists s_1, ..., s_a  \\in  \\{0,1\\}^r\\)`} such
                that{" "}
                {`\\(\\{0,1\\}^r  =  \\bigcup_{i=1}^n (Z_x \\oplus s_i)\\)`}.
                Since {`\\(s_i\\)`} is independent,{" "}
                {`\\(\\forall y  \\in  \\{0,1\\}^r\\)`} and since{" "}
                {`\\(\\Pr(y\\in Z_x\\oplus s_i)<14>\\Pr\\bigg(y\\notin \\bigcup_{i=1}^a Z_x \\oplus s_i\\bigg) = \\prod_{i=1}^a \\Pr(y\\notin Z_x \\oplus s_i) < 2^{-na}<15> x\\in L\\iff \\exists(s_1, ..., s_a), \\forall y\\in\\{0,1\\}^r, B(x, s_1, ..., s_a) = 1<16>\\boxed{\\mathrm{BPP} \\subseteq \\Sigma_2^P \\cap \\Pi_2^P}\\)`}
                $
              </p>
              <p>This proves the claim.</p>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </>
  );
};

export default SipserLautemann;
