import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const GlobalNavBar = ({ style }) => {
    const linkMap = {
        "/about-me": "About Me",
        "/publications": "Publications",
        "/teaching": "Teaching",
        "/cv": "CV",
        "/blog": "Blog",
    }
    const location = useLocation()

    return (
        <div className="particles-navbar-wrapper" style={style}>
            <Navbar className="py-3" collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Link className="navbar-brand" to="/">
                        <div><span className="fw-bold">Emile</span> Timothy Anand</div>
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {Object.entries(linkMap).map(([path, title]) => (location.pathname.startsWith(path)) ?
                                (<Link className="nav-link active" key={path} to={path}>{title}</Link>) :
                                (<Link className="nav-link" key={path} to={path}>{title}</Link>))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default GlobalNavBar;