import { motion } from 'framer-motion';
import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import GlobalFooter from "../components/GlobalFooter";
import GlobalNavBar from "../components/GlobalNavBar";
import teaching from '../img/teaching.jpg';
import teaching2 from '../img/teaching2.jpg';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'

const Table = styled.table`
    & {
        margin: 1rem 0;
    }
    & td {
        padding: 0 1.5rem;
    }
`

const mainContent = <>
    <p>Much of my what has gone “right” in my life can be traced back to exceptional teaching, both inside and outside of the classroom. I care deeply about passing on what I've learned in order to help make theoretical CS and machine learning (and related endeavors) more approachable fields for those aspiring to be both traditional computer scientists and “non-computer” scientists.</p>

    <p>Of course, a lot of what I have learnt has come from being exposed to amazing people whose mentorship and guidance I am immensely grateful for! They include:</p>
    <ul>
        <li>Professors: <span className="fw-bold">Chris Umans</span> (TCS @ Caltech), <span className="fw-bold">Adam Wierman</span> (ML @ Caltech), <span className="fw-bold">Guannan Qu</span> (ML @ CMU), <span className="fw-bold">David Conlon</span> (Math @ Caltech), <span className="fw-bold">Katie Bouman</span> (ML @ Caltech), <span className="fw-bold">Jan Van der Brend</span> (TCS @ GeorgiaTech), <span className="fw-bold">Yisong Yue</span> (ML @ Caltech)</li>
        <li>Graduate Students: <span className="fw-bold">Yiheng Lin</span> (RSRG @ Caltech), <span className="fw-bold">Chris Yeh</span> (RSRG @ Caltech), <span className="fw-bold">Erik Jahn</span> (TCS @ Caltech), <span className="fw-bold">Sike Wang</span> (TCS @ Caltech)</li>
        <li>Undergrads: <span className="fw-bold">Vinayak Kumar</span>, <span className="fw-bold">Simon Ricci</span>, <span className="fw-bold">Mayank Pandey</span>, <span className="fw-bold">Kellen Rodriguez</span>, <span className="fw-bold">Julen Lujambio</span>, and <span className="fw-bold">Haoyuan Sun</span></li>
    </ul>
    <p></p>
    <p></p>
    <p></p>

    <h5>Courses I've TA'ed for:</h5>
    <Table>
        <tr>
            <td>Complexity Theory, 2023 <i>(Head TA)</i></td>
            <td>Prof. Chris Umans</td>
        </tr>
        <tr>
            <td>Discrete Mathematics, 2021, 2022</td>
            <td>Prof. David Conlon</td>
        </tr>
        <tr>
            <td>Machine Learning and Data Mining, 2023 <i>(Head TA)</i></td>
            <td>Prof. Yisong Yue</td>
        </tr>
        <tr>
            <td>Introduction to Algorithms, 2020 (TA)</td>
            <td>Prof. Leonard Schulman</td>
        </tr>
        <tr>
            <td>Learning from Data 2021, 2022 <i>(Head TA)</i></td>
            <td>Prof. Yaser Abu-Mostafa</td>
        </tr>
        <tr>
            <td>Data-Adaptive Control, 2023</td>
            <td>Prof. Eugene Lavretsky</td>
        </tr>
        <tr>
            <td>Seminar on Quantum Information, 2022</td>
            <td>Prof. Urmila Mahadev</td>
        </tr>
        <tr>
            <td>Decidability and Tractability, 2021 <i>(Head TA)</i></td>
            <td>Prof. Chris Umans</td>
        </tr>
    </Table>

    <h3>Community Service</h3>
    <p>
        During my undergrad at Caltech, I was a <span className="fst-italic">deans tutor</span>, and I volunteered many hours each week to tutor fellow students in topics like algorithms, discrete math, machine learning, probability, topology, and combinatorial analysis. I was also a Caltech Y and Caltech RISE tutor between 2019-2023, where I tutored students from San Marino High School, UC San Diego, and Pasadena Community College.
    </p>
</>

const Teaching = () => {
    // const ease = [0.08, 0.37, 0.45, 0.89]
    const showCols = useMediaQuery({
        query: '(min-width: 992px)'
    })

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
        style={{ position: 'relative', minHeight: '100vh' }}
    >
        <>
            <GlobalNavBar />
            <Container fluid={true} className="py-3">
                {!showCols && <>
                    <h3>Teaching</h3>
                    <Row>
                        <Col md={6}><img className="w-100 rounded mb-3" src={teaching2} /></Col>
                        <Col md={6}><img className="w-100 rounded mb-3" src={teaching} /></Col>
                    </Row>
                    {mainContent}
                </>}
                {showCols && <Row>
                    <Col md={4} className="px-5">
                        <img className="my-3 w-100 rounded" src={teaching2} />
                        <img className="w-100 rounded" src={teaching} />
                    </Col>
                    <Col md={8} className="pe-5">
                        <h3>Teaching</h3>
                        {mainContent}
                    </Col>
                </Row>}
            </Container>
            <GlobalFooter bottomSticky={false} />
        </>
    </motion.article>)
}

export default Teaching;