import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import GlobalFooter from "../components/GlobalFooter";
import GlobalNavBar from "../components/GlobalNavBar";
import PubItem, { PubContainer } from "../components/PubItem";
import { misc, publications, talks } from "./constants";

const Publications = () => {
  // const ease = [0.08, 0.37, 0.45, 0.89];

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <>
        <GlobalNavBar />
        <Container className="py-4">
          <h2>Publications</h2>
          <PubContainer>
            {publications.map(({ title, journal, authors, url }) => (
              <PubItem
                title={title}
                journal={journal}
                authors={authors}
                url={url}
              />
            ))}
          </PubContainer>

          <h2 className="mt-4">Miscellaneous</h2>
          <PubContainer>
            {misc.map(({ title, journal, authors, url }) => (
              <PubItem
                title={title}
                journal={journal}
                authors={authors}
                url={url}
              />
            ))}
          </PubContainer>

          <h2 className="mt-4">Talks</h2>
          <PubContainer>
            {talks.map(({ title, journal, authors, url }) => (
              <PubItem
                title={title}
                journal={journal}
                authors={authors}
                url={url}
              />
            ))}
          </PubContainer>
        </Container>
        <GlobalFooter />
      </>
    </motion.article>
  );
};

export default Publications;
