import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import { CodeBlock, nord } from "react-code-blocks";
import Accordion from "react-bootstrap/Accordion";
import covidpic2 from "../../img/bg/covidpic2.jpeg";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const Covid19 = () => {
  const ease = [0.08, 0.37, 0.45, 0.89];

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={covidpic2}>
            <Header>
              Correlating COVID-19 outbreaks to COVID-19 Vaccine Efficacy
            </Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p>
                During the spring quarter of my sophomore year at Caltech, I
                took CS 156b (Learning from Data), which is a graduate-level
                project-based class on Machine Learning taught by{" "}
                <a href="https://work.caltech.edu/">
                  Professor Yaser Abu-Mostafa
                </a>
                . Since this was during the peak of the COVID-19 pandemic,
                Prof. Abu-Mostafa asked us to pursue a ML project of our choice
                using any available data to produce novel and meaningful
                insights about COVID-19. So, my friends (Julen, Andrew, and
                Basel, who are really smart guys) and I, decided to use existing
                data to determine how effective the COVID-19 vaccine was against
                the dominant variants at the time (B.1.1.7, P.1, B.1.351,
                B.1.427, B.1.429).
              </p>
              <p>
                We used GISAID-scraped data and data from Outbreak.info, OWID,
                CDC, which represented regularly-updating COVID-19 variant
                outbreaks in different states in the United States. We used this
                data to train a couple of models: LSTM (Long Short-term Memory),
                VAR (Vector Auto-Regression), SIR (Susceptible, Infectious,
                Recovered) model, ARIMA (Autoregressive Integrated Moving
                Average), and GPR (gaussian process regression). Specifically,
                we looked at vaccine-delivery data and variant-outbreaks to see
                if we could find a negative correlation. My contribution to this
                project was the development of the ARIMA and GPR models, which
                are tools used primarily in modeling highly volatile data, like
                stocks. I also looked at XGBoost, but decided against using it
                due its lack of scalability since the data changed so
                sporadically. Our final machine-learning model ensembled the
                ARIMA, LSTM, and VAR models, to yield a pretty accurate estimate
                of the COVID-19 vaccine efficacy.
              </p>
              <p>
                Here, you’ll see my raw source-code for developing the ARIMA
                model, as well as the mostly self-contained slides for the three
                virtual presentations that we gave over the course of the
                quarter.
              </p>
              <h2 id="implementation-of-the-arima-model">
                Implementation of the ARIMA model
              </h2>
              <p>
                <a href="https://gist.github.com/emiletimothy/220a95a143e4b10b387bb86a76d179f3.js">
                  Embed
                </a>
              </p>
              <h2 id="first-progress-presentation">
                First Progress Presentation
              </h2>
              <iframe
                src="https://drive.google.com/file/d/1qblcNkcmsOotH3E9LwouM1p6O93vNdNK/preview"
                width="100%"
                height="720"
              ></iframe>
              <h2 id="second-progress-presentation">
                Second Progress Presentation
              </h2>
              <iframe
                src="https://drive.google.com/file/d/14Q5EPkHPstp0fpmfVioqt_EeTs_uGCdL/preview"
                width="100%"
                height="720"
              ></iframe>
              <h2 id="final-presentation">Final Presentation</h2>
              <iframe
                src="https://drive.google.com/file/d/1kBlUD-OBhqrQ4gW0z6W-6JHB7LLRXAGr/preview"
                width="100%"
                height="720"
              ></iframe>
              <p>
                A quick digression: While other groups in the class had
                elaborate names like ‘Super Unsupervised’ or ‘Indecision Trees’,
                we decided to name ourselves as ‘Group 23’ for an odd, though
                funny, reason. The story is that we were given a google sheets
                form to register our group and its name. However, we decided
                that instead of going to the trouble of choosing a witty (and
                potentially, awkward) name, we would be happier messing with
                another group. So, we registered our group as ‘Group 23’ on row
                24 on the spreadsheet in the hopes that the Group that signed up
                on row 23 of the spreadsheet would be thrown into confusion …
                Alas, it turned out that the group that signed up on row 23
                never looked at the spreadsheet again, which was quite
                unfortunate. Moral of the story: our collective prank game needs
                to get stronger.
              </p>
              <p>
                This was a really enjoyable class; and, even though it was
                entirely virtual, Professor Abu-Mostafa made it very memorable.
                I’d also like to acknowledge TAs Chris Wang, Dominic Yurk, and
                Alexander Zlokapa for their feedback throughout the class.
              </p>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </>
  );
};

export default Covid19;
