import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import { CodeBlock, nord } from "react-code-blocks";
import Accordion from "react-bootstrap/Accordion";
import Spectre from "../../img/blog/Spectre.png";
import Meltdown from "../../img/blog/Meltdown.png";
import binary2 from "../../img/bg/binary2.jpeg";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const ShellCodeMeltdownExploits = () => {
  const ease = [0.08, 0.37, 0.45, 0.89];

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={binary2}>
            <Header>Meltdown and Spectre Exploits</Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p>
                Hi all! I’m writing this post to raise awareness about an old
                bug in a series of microprocessor that was uncovered in 2015
                luckily by a group of white-hat hackers who eventually helped
                create a security patch update. This vulnerability, however, cut
                quite deep and could have left any computer with these
                microprocessors open to a host of extremely malicious attacks
                from anyone. Below, I describe the attack, its impacts, and
                (since it’s now safe to do so because of the security patch) a
                code that I used to hack into a ‘fake’ unpatched server for my
                Computing Systems class (don’t worry, this was completely legal
                - the Profesesor made us do this as an assignment!) Moral of the
                story - all systems, even ridiculously strong and robust ones
                have vulnerabilities and are open to attack: Beware!
              </p>
              <p>
                Meltdown and Spectre are cyber-attacks on the Intel microchip
                that exploits vulnerabilities within a computer processor. The
                Spectre and Meltdown codes allow hackers to force any program
                that is running in the computer processor to obtain its
                encryption key. This, in turn, gives said hacker the ability to
                access the entire program. This is an obvious security exploit,
                because such programs should only be accessed by entities who
                are required to have access to it. The implications of accessing
                the program are twofold: 1) The hacker will be able to access
                the kernel memory, which is the most protected part of the OS,
                2) After reading the encryption key, the “user will be able to
                access all the data in the computer from browsing history to
                personal conversations (Mike Hamburg, Strategy News)”.
              </p>
              <p>
                In particular, the vulnerability that the Spectre and Meltdown
                codes executes is the fact that computer processors use
                speculative execution and branch prediction when maximizing
                performance. In particular, the processors attempt to predict
                which branch a program takes. The processor does not actually
                confirm this detination until the actual computing is confirmed.
                These codes train the processor to speculatively perform
                operations that would not happen otherwise, and it does this, in
                particular, by training the processor to reeach a certain branch
                of the computation and then speculatively exit. More
                specifically, speculative execution is allowed access to all
                memory’s and registers (including access to the kernel), and
                when the user manipulates the way in which branch prediction
                occurs, it trains the speculative execution process to reach
                into kernel memory and return it. This automatically gives the
                hacker access to the kernel memory, and therefore, access to
                everything in the computer.
              </p>
              <p>
                How exactly does the code retrain the processor’s speculative
                execution? The Spectre and Meltdown codes enters isolated
                memories through “data exfiltration of the microarchitectural
                covert channels (Abu Ghazaleh, Spectrum)”. In particular, the
                code locates addresses of the instructions to go to the kernel
                space, and this then leaks contents of specific registers. The
                code then tricks the processor to execute this instruction
                incorrectly such that its final destination is in the kernel.
                The covert channel is then used by the hacker to obtain
                information stored in the computer.
              </p>
              <p>
                Spoken plainly, the Meltdown and Spectre exploits critical
                vulnerabilities in modern processors to steal data which is
                currently processed on the computer. While programs are
                typically not permitted to read data from other programs, a
                malicious program can exploit Meltdown and Spectre to get hold
                of secrets stored in the memory of other running programs. This
                might “include your passwords stored in a password manager or
                browser, your personal photos, emails, instant messages and even
                business-critical documents” (Graz, 2014). “Meltdown and Spectre
                work on personal computers, mobile devices, and in the cloud.
                Depending on the cloud provider’s infrastructure, it might be
                possible to steal data from other customers” (Graz, 2014).
              </p>
              <p>
                It is worth noting that Intel, (the primary company affected)
                created a security update on all new processors in which they
                “prepared patches to mitigates these flaws (Paul Kocher,
                Spectre)” wherein the new class of processors (codenamed Cascade
                Lake) would use deep-learning to boost instructions and
                mitigations to prevent Spectre and Meltdown attacks.
              </p>
              <h2 id="spectre-attack">Spectre Attack</h2>
              <figure>
                <img src={Spectre} alt="Spectre attack" />
                <figcaption aria-hidden="true">Spectre attack</figcaption>
              </figure>
              <h2 id="meltdown-attack">Meltdown Attack</h2>
              <figure>
                <img src={Meltdown} alt="Meltdown attack" />
                <figcaption aria-hidden="true">Meltdown attack</figcaption>
              </figure>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>Shellcode Script (Hacking a Server):</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Here, I present the code that I used to hack into the
                      server for my Computing Systems class! (Don’t worry, this
                      was legal - our Professor asked us to do it as an
                      assignment!)
                    </p>
                    <p>SCRIPT HERE:</p>
                    <script src="https://gist.github.com/emiletimothy/bb68b02cba13b9e3b76ffa4cbface3db.js"></script>
                    <p>
                      Here is some assembly language code (X86-64) that was used
                      to override specific system-level commands:
                    </p>
                    SCRIPT HERE:
                    <script src="https://gist.github.com/emiletimothy/6b72ea00751517b3dbb5cce3dff2a605.js"></script>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </>
  );
};

export default ShellCodeMeltdownExploits;
