import React from "react";
import { Container } from "react-bootstrap";
import { Mortarboard } from "react-bootstrap-icons";
import styled from "styled-components";
import fbLogo from "../img/icons/fb.svg";
import githubLogo from "../img/icons/github.svg";
import igLogo from "../img/icons/instagram.svg";
import inLogo from "../img/icons/linkedin.svg";
import orcidLogo from "../img/icons/orcid_logo.png";
import { ReactSVG } from "react-svg";

const ContainerFluid = ({ className, children }) => (
  <Container fluid={true} className={className}>
    {children}
  </Container>
);

const DarkContainer = styled(ContainerFluid)`
  ${({ bottomSticky }) => bottomSticky && "position: absolute; bottom: 0;"}
  background-color: #212529;

  div {
    display: flex;
    justify-content: center;
  }

  svg {
    margin-left: 1rem;
  }
`;

const Icon = styled.div`
  height: 20px;
  width: 20px;
  background-color: white;
  mask: url(${(props) => props.src}) no-repeat center;
  margin-left: 1rem;
`;

const OrcidIcon = styled.div`
  height: 32px;
  width: 32px;
  background-color: white;
  mask: url(${(props) => props.src}) no-repeat center;
  margin-left: 1rem;
`;

const GlobalFooter = ({ bottomSticky }) => {
  return (
    <DarkContainer className="p-5" bottomSticky={bottomSticky}>
      <div>
        <a target="_blank" href="https://www.instagram.com/emiletimothy/">
          <Icon src={igLogo} />
        </a>
        <a target="_blank" href="https://www.linkedin.com/in/emiletimothy/">
          <Icon src={inLogo} />
        </a>
        <a
          target="_blank"
          href="https://scholar.google.com/citations?user=nUXwVU8AAAAJ&hl=en/"
        >
          <Mortarboard color="white" size={20} />
        </a>
        <a target="_blank" href="https://github.com/emiletimothy/">
          <Icon src={githubLogo} />
        </a>
      </div>
      <p
        className="text-center mt-2"
        style={{
          color: "white",
          fontFamily: "monospace",
          fontSize: "var(--bs-body-font-size);",
        }}
      >
        emiletimothy [at] outlook [dot] com
      </p>

      <h6 className="mt-3 mb-0" style={{ color: "white", textAlign: "center" }}>
        © 2024 by Emile Timothy
      </h6>
      <p className="mb-0 mt-3" style={{ color: "white" }}>
        Georgia Institute of Technology,
      </p>
      <p className="mb-0" style={{ color: "white" }}>
        Klaus Advanced Computing Building 2116,
      </p>
      <p className="mb-0" style={{ color: "white" }}>
        Atlanta, Georgia, 30308
      </p>
    </DarkContainer>
  );
};

export default GlobalFooter;
