import { MathJaxNode, MathJaxProvider } from '@yozora/react-mathjax';
import { motion } from 'framer-motion';
import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import GlobalFooter from "../components/GlobalFooter";
import GlobalNavBar from "../components/GlobalNavBar";
import music from '../img/music.jpg';
import outreach from '../img/outreach.png';
import literature from '../img/literature.png';
import JsxParser from 'react-jsx-parser';
import { useMediaQuery } from 'react-responsive'

const mainContent1 = <>
    <p>
        I passionately advocate for the UN sustainable development agenda, with a focus towards environmental sustainability, youth engagement, and global education.
    </p>
    <p>
        As a campaign manager of the youth-led UN-affiliated NGO Green Hope, I spearheaded global initiatives aimed to drive positive change. I conducted outreach at the Rohingya Refugee Camps in Kutupalong, Bangladesh, extending support to vulnerable communities and promoting sustainable practices. I also volunteered at HIV/AIDS camps and orphanages in Kathmandu, Nepal, aiming to support and empower communities in need. My commitment has extended to the policy platform, where I was the moderator for the Amazon Sustainability Conference in Paramaribo, Suriname in 2020, where I was fortunate to be hosted by the President of Suriname, Chan Santokhi. I have also collaborated with the United Nations Environment Programme (UNEP) and the United Nations High Commissioner of Refugees (UNHCR) to drive grassroots projects among the youth in Paramaribo, Suriname, and for preservation of the Amazon rainforest and surrounding mangroves. In 2022, I co-led an initiative to plant 10,000 Ghaf trees in Dubai.
    </p>
</>

const mainContent2 = <>
    <p>
        I truly enjoy playing the piano. You can see this from the totally not-staged picture of me smiling as I play the piano. No, but really, I've played the piano for as long as I can remember. There was once a point in my life where I could read sheet music better than I could read English. I have found that music can bring you joy, it can give you energy and it can help you express your deepest feelings. I have had the deepest privilege to study piano and music theory under a number of great soloists (including Jelena Matvejevskaya and Ghadeer Abeidoh). My musical ancestry traces to the great rector, Ivari Ilja. I have distinctions in the Trinity College piano diploma and music theory courses. In the past, I have had the pleasure of performing in the Kuala Lumpur convention center at the World Scholars Cup in 2019, and at the Dubai Royal Recital in 2018. My repertoire focuses on pieces from Beethoven, including Moonlight Sonata (3rd movement) and “Pathétique” No. 8 in C minor, Op. 13. Recently, I have started to enjoy playing Camille Saint Saens' Danse Macabre (which is derived from Lizst's Totentanz of macabre-themed works) and contemporary popular pieces such as Unravel (from Tokyo Ghoul), Interstellar (by Hans Zimmer), and City of Stars (Justin Hurwitz). See my youtube channel @emiletimothy for occasional recordings!
    </p>
</>

const Extracurriculars = () => {
    // const ease = [0.08, 0.37, 0.45, 0.89]
    const showCols = useMediaQuery({
        query: '(min-width: 992px)'
    })

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <>
            <GlobalNavBar />
            <Container fluid={true} className="py-3">
                {!showCols && <>
                    <h3>Outreach</h3>
                    <img className="w-100 rounded" src={outreach} />
                    {mainContent1}

                    <h3>Music</h3>
                    <img className="w-100 rounded" src={music} />
                    {mainContent2}
                </>}
                {showCols && <>
                    <Row>
                        <Col md={4} className="px-5">
                            <img className="w-100 rounded" src={outreach} />
                        </Col>
                        <Col md={8} className='pe-5'>
                            <h3>Outreach</h3>
                            {mainContent1}
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={4} className="px-5">
                            <img className="w-100 rounded" src={music} />
                        </Col>
                        <Col md={8} className='pe-5'>
                            <h3>Music</h3>
                            {mainContent2}
                        </Col>
                    </Row>
                </>}
            </Container>
            <GlobalFooter />
        </>
    </motion.article>)
}

export default Extracurriculars;