import ShellCodeMeltdownExploits from "./pages/blog/shell_code_meltdown_exploits";
import Embeddings from "./pages/blog/embeddings";
import OnlineSecretary from "./pages/blog/online_secretary";
import LipschitzAndBernstein from "./pages/blog/lipschitz_and_bernstein";
import PrisonersProblem from "./pages/blog/prisoners_problem";
import MartingaleInequalities from "./pages/blog/martingale_inequalities";
import ListDecoding from "./pages/blog/list_decoding";
import FormalComplexity from "./pages/blog/formal_complexity";
import MahaneyTheorem from "./pages/blog/mahaney_theorem";
import BarringtonTheorem from "./pages/blog/barrington_theorem";
import NlRelations from "./pages/blog/nl_relations";
import UnpleasantChords from "./pages/blog/unpleasant_chords";
import TodaTheorem from "./pages/blog/toda_theorem";
import SipserLautemann from "./pages/blog/sipser_lautemann";
import KarpLipton from "./pages/blog/karp_lipton";
import VcSigma from "./pages/blog/vc_sigma";
import DimensionalityReduction from "./pages/blog/dimensionality_reduction";
import Covid19 from "./pages/blog/covid19";
import LigoSurf2020 from "./pages/blog/ligo_surf_2020";
import Exp from "./pages/blog/exp";
import Hurdle1 from "./pages/blog/hurdle1";
import Hmm from "./pages/blog/hmm";
import StableSystems from "./pages/blog/stable_systems";
import Hurdle2 from "./pages/blog/hurdle2";
import SamplingCrisis from "./pages/blog/sampling_crisis";

export const URLS = Object.freeze({
  shell_code_meltdown_exploits: "/blog/meltdown-and-spectre-exploits",
  embeddings: "/blog/a-formal-introduction-to-embeddings",
  online_secretary: "/blog/an-online-algorithm-to-hire-the-best-candidate",
  lipschitz_and_bernstein:
    "/blog/arbitrary-precision-approximations-of-lipschitz-functions-by-linear-combinations-of-bernstein-polynomials",
  prisoners_problem:
    "/blog/using-permutations-to-understand-the-100-prisoners-problem-and-its-variant",
  martingale_inequalities:
    "/blog/using-martingales-to-derive-robust-concentration-inequalities-in-probability-theory",
  list_decoding:
    "/blog/list-decoding-of-the-hadamard-reed-solomon-and-parvaresh-vardy-codes",
  formal_complexity:
    "/blog/the-quadratic-space-complexity-of-the-parity-function-showing-that-47",
  mahaney_theorem:
    "/blog/mahaneys-theorem-on-the-conditions-for-the-np-completeness-of-sparse-languages",
  barrington_theorem:
    "/blog/barringtons-theorem-for-permutation-branching-programs-for-10-languages",
  nl_relations:
    "/blog/relations-on-nl-non-deterministic-log-space-through-undirected-st-connectivity",
  unpleasant_chords: "/blog/why-do-most-chords-sound-unpleasant-to-the-ear",
  toda_theorem: "/blog/proving-todas-theorem-80",
  sipser_lautemann: "/blog/proving-the-sipser-lautemann-theorem-18",
  karp_lipton: "/blog/proving-the-karp-lipton-theorem-47",
  vc_sigma: "/blog/showing-that-the-vc-dimension-is-17-complete",
  dimensionality_reduction:
    "/blog/variance-propagation-in-dimensionality-reduction-embeddings",
  covid19: "/blog/correlating-covid-19-outbreaks-to-covid-19-vaccine-efficacy",
  ligo_surf_2020: "/blog/ligo-acoustic-invisibility-cloak",
  exp: "/blog/enlarging-the-pseudorandomness-of-expander-random-walks",
  hurdle1:
    "/blog/physics-11-hurdle-1-airline-traffic-at-san-francisco-international-airport",
  hmm: "/blog/hidden-markov-models-to-generate-shakespearean-sonnets",
  stable_systems:
    "/blog/online-adaptive-controller-selection-for-the-stable-control-of-noisy-dynamical-systems",
  hurdle2: "/blog/physics-11-hurdle-2-fire-in-the-serengeti",
  sampling_crisis:
    "/blog/identifying-chemicals-in-metabolomic-samples-through-dimensionality-reduction",
});
export const BLOG_ROUTES = [
  {
    path: URLS.shell_code_meltdown_exploits,
    element: <ShellCodeMeltdownExploits />,
  },
  { path: URLS.embeddings, element: <Embeddings /> },
  { path: URLS.online_secretary, element: <OnlineSecretary /> },
  { path: URLS.lipschitz_and_bernstein, element: <LipschitzAndBernstein /> },
  { path: URLS.prisoners_problem, element: <PrisonersProblem /> },
  { path: URLS.martingale_inequalities, element: <MartingaleInequalities /> },
  { path: URLS.list_decoding, element: <ListDecoding /> },
  { path: URLS.formal_complexity, element: <FormalComplexity /> },
  { path: URLS.mahaney_theorem, element: <MahaneyTheorem /> },
  { path: URLS.barrington_theorem, element: <BarringtonTheorem /> },
  { path: URLS.nl_relations, element: <NlRelations /> },
  { path: URLS.unpleasant_chords, element: <UnpleasantChords /> },
  { path: URLS.toda_theorem, element: <TodaTheorem /> },
  { path: URLS.sipser_lautemann, element: <SipserLautemann /> },
  { path: URLS.karp_lipton, element: <KarpLipton /> },
  { path: URLS.vc_sigma, element: <VcSigma /> },
  { path: URLS.dimensionality_reduction, element: <DimensionalityReduction /> },
  { path: URLS.covid19, element: <Covid19 /> },
  { path: URLS.ligo_surf_2020, element: <LigoSurf2020 /> },
  { path: URLS.exp, element: <Exp /> },
  { path: URLS.hurdle1, element: <Hurdle1 /> },
  { path: URLS.hmm, element: <Hmm /> },
  { path: URLS.stable_systems, element: <StableSystems /> },
  { path: URLS.hurdle2, element: <Hurdle2 /> },
  { path: URLS.sampling_crisis, element: <SamplingCrisis /> },
];
