import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import GlobalNavBar from "../components/GlobalNavBar";
import profile_img from "../img/home-profile2.jpg";
import GlobalFooter from "../components/GlobalFooter";
import { motion } from "framer-motion";
import { ScrollRestoration } from "react-router-dom";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { TimelineOppositeContent } from "@mui/lab";
import styled from "styled-components";
import { ThemeProvider, createTheme } from "@mui/material";
import { blue, indigo } from "@mui/material/colors";

const black = "#000000";
const theme = createTheme({
  palette: {
    primary: {
      main: black,
      contrastText: "#ffffff",
    },
  },
});

const ResponsiveTimeline = styled(Timeline)`
  @media (min-width: 990px) {
     {
      width: 75%;
    }
  }
`;

const ParaLink = ({ children, href }) => {
  return (
    <motion.a
      whileHover={{
        textDecorationLine: "underline",
        color: "#FF6347",
      }}
      href={href}
    >
      {children}
    </motion.a>
  );
};

const Home = () => {
  const ease = [0.08, 0.37, 0.45, 0.89];

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <>
      <GlobalNavBar />
      <Container className="py-4 mt-3">
        <Row className="mb-5">
          <Col md={6}>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5, duration: 1, ease },
                },
              }}
            >
              <motion.img
                whileHover={{ scale: 1.01 }}
                src={profile_img}
                className="d-block w-100 rounded"
              />
            </motion.div>
          </Col>
          <Col md={6}>
            <motion.p>
              Hello there, my name is Emile! I am a first-year PhD student in
              Computer Science at the{" "}
              <ParaLink href="https://www.gatech.edu/">
                Georgia Institute of Technology
              </ParaLink>
              , doing the Algorithms, Combinatorics, Optimization (
              <ParaLink href="https://aco.gatech.edu/">ACO</ParaLink>) program,
              where I have the pleasure of exploring my research interests in
              designing efficient machine learning algorithms with my advisor,{" "}
              <ParaLink href="https://faculty.cc.gatech.edu/~vdbrand/">
                Professor Jan van den Brand
              </ParaLink>
              . Previously, I graduated from{" "}
              <ParaLink href="https://caltech.edu/">Caltech</ParaLink>, with
              degrees in Computer Science and Math in 2023, with the excellent
              mentorship of my advisors, Professors{" "}
              <ParaLink href="https://www.cs.caltech.edu/~umans/">
                Chris Umans
              </ParaLink>
              ,{" "}
              <ParaLink href="https://adamwierman.com/">Adam Wierman</ParaLink>,
              and{" "}
              <ParaLink href="https://users.cms.caltech.edu/~klbouman/">
                Katie Bouman
              </ParaLink>
              . During this time, I was also fortunate to spend time as a
              visiting student at{" "}
              <ParaLink href="https://www.cmu.edu/">
                Carnegie Mellon University
              </ParaLink>{" "}
              where I was advised by Professor{" "}
              <ParaLink href="https://www.guannanqu.com/">Guannan Qu</ParaLink>.
            </motion.p>
            <motion.p>
              My research interests lie in machine learning and theoretical
              computer science. I have eclectic interests in machine learning,
              deep learning, reinforcement learning, optimization, and gradient
              flows. I’m also interested in problems related to high dimensional
              expanders, dynamic data structures, and algebraic fine-grained
              complexities. Feel free to connect with me. I welcome any comments
              or questions you might have about my research, and am always eager
              to discuss and share ideas!
            </motion.p>
            <motion.p>
              When I’m not doing research, I enjoy playing the piano and
              training for duathlons. Trivia: My Erdös number is 3.
            </motion.p>{" "}
          </Col>
        </Row>

        {/* NEWS TIMELINE SECTION */}
        <h3 className="fw-bold">News</h3>
        <ThemeProvider theme={theme}>
          <ResponsiveTimeline>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(11/2023)</span> I visited Princeton
                University's mathematics department for a week to work with
                Professor Rose McCarty.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(09/2023)</span> My paper, Online
                Adaptive Policy Selection in Time-Varying Systems: No-Regret via
                Contractive Perturbations, was accepted at NeurIPS 2023.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(09/2023)</span> I submitted a paper
                on dynamic algebraic algorithms, which presents a general
                framework for creating fast algorithms, to ITCS 2024.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(08/2023)</span> I joined Carnegie
                Mellon University as a research assistant in theoretical machine
                learning with Professor Guannan Qu.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(06/2023)</span> I won the Housner
                Discovery Fund Award at Caltech (Prize fellowship founded by
                George W. Housner, and awarded to 3 each year to be used for
                attending conferences).
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(04/2023)</span> I was named a Daniels
                Fellow in Theoretical Computer Science (Prize fellowship awarded
                by University of Chicago).
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(04/2021)</span> I was named a Richard
                Brewer fellow at Caltech (Prize fellowship founded by Richard
                Brewer and awarded for outstanding solutions to the Physics 11
                hurdles; About 5 recipients in Caltech each year).
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(06/2019)</span> I was awarded an
                honorary position the UAE International Physics Olympiad (IPhO)
                team 2019 after winning 1st place at the UAE Physics Olympiad.
                Unfortunately, in 2019, travel restrictions prevented the UAE
                team from being entered in the competition.
              </TimelineContent>
            </TimelineItem>
          </ResponsiveTimeline>
        </ThemeProvider>
      </Container>
      <GlobalFooter bottomSticky={false} />
      <ScrollRestoration />
    </>
  );
};

export default Home;
