import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const PubContainer = styled.ul`
    // div:not(:first-child) {
    //     margin-top: 1.5rem;
    // }
`;

const PubWrapper = styled.li`
    a {
        color: #003399;
    }

    a:hover {
        text-decoration: underline !important;
    }

    .pub-title {
        display: inherit;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0;
    }

    .pub-journal {
        margin-bottom: 0.5rem;
    }

    p {
        margin-bottom: 0;
    }
`

const PubItem = ({ title, journal, authors, url }) => {
    return (
        <PubWrapper>
            {!url ? <h5 className="pub-title">{title}</h5> : (<a target="_blank" rel="noreferrer" href={url}><h5 className="pub-title">{title}</h5></a>)}
            {!authors ? <></> : <p className="pub-authors">with {authors}</p>}
            <p className="pub-journal">{journal}</p>
        </PubWrapper>
    )
}

export default PubItem;