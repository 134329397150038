import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import { CodeBlock, nord } from "react-code-blocks";
import Accordion from "react-bootstrap/Accordion";
import eq6 from "../../img/bg/eq6.jpeg";

const StylesDiv = styled.div`
  & {
    overflow-x: auto;
  }

  & img {
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  & .grid-layout {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (min-width: 990px) {
      // grid-auto-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ResponsiveContainer = styled.div`
  background-color: #fff;

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: -10%;
    margin-bottom: 2rem;
  }
  @media (min-width: 990px) {
    width: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.cover});
  background-size: cover;
  height: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    align-items: normal;
    height: 600px;
  }
`;

const Header = styled.div`
  height: fit-content;
  background-color: white;
  color: black;
  font-weight: bold;
  // mix-blend-mode: screen;
  font-family: "Oswald", sans-serif;
  text-shadow: 3px 3px 3px #ababab;
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
  padding: 10px;

  @media only screen and (min-width: 768px) {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 3rem;
    width: 60%;
  }
`;

const StyledArticle = styled(motion.article)`
  background-color: #f1f1f1;
`;

const TodaTheorem = () => {
  const ease = [0.08, 0.37, 0.45, 0.89];

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <>
      <StyledArticle
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <>
          <GlobalNavBar />
          <HeaderContainer cover={eq6}>
            <Header>
              Proving Toda’s Theorem:{" "}
              {`\\(\\text{PH} \\subseteq \\text{P}^\\text{#P}\\)`}
            </Header>
          </HeaderContainer>
          <ResponsiveContainer className="container p-3">
            <StylesDiv>
              <p>
                The purpose of this post is to describe an odd complexity class,{" "}
                {`\\(\\oplus \\text{P}\\)`} (pronounced parity-P), which wasn’t
                known to be powerful or even useful until the discovery of
                Toda’s theorem (which I’m going to prove). Toda’s theorem claims
                that the computational power of the polynomial hierarchy (the
                set of all problems that can be solved in polynomial time,
                irrespective of other resources) is completely equal to the
                ability to count polynomially long things. This is a cool result
                because it highlights the intricate value of being able to count
                things - the ability to count allows you to also solve any
                problem in the polynomial hierarchy.
              </p>
              <p>
                I’m going to start by defining {`\\(\\text{NP}, \\text{BPP}\\)`}
                , and {`\\(\\oplus \\text{P}\\)`} in terms of computational
                paths. Specifically, a language {`\\(L\\)`} is in:
              </p>
              <ol type="1">
                <li>
                  <p>
                    {`\\(\\text{NP}\\)`} iff there is such a polynomial-time
                    nondeterministic Turing Machine {`\\(M\\)`} for which{" "}
                    {`\\(x \\in L\\)`} implies that at least one of the
                    computation paths accepts, and {`\\(𝒙 \\notin L\\)`} implies
                    that no computation paths accept.
                  </p>
                </li>
                <li>
                  <p>
                    {`\\(\\text{BPP}\\)`} iff there is such a polynomial-time
                    nondeterministic Turing Machine {`\\(M\\)`} for which{" "}
                    {`\\(x \\in L\\)`} implies that at least{" "}
                    {`\\(\\frac{2}{3}\\)`} of the computation paths accept, and{" "}
                    {`\\(x \\notin L\\)`} implies that at most{" "}
                    {`\\(\\frac{1}{3}\\)`} of the computation paths accept.
                  </p>
                </li>
                <li>
                  <p>
                    {`\\(\\oplus \\text{P}\\)`} iff there is such a
                    polynomial-time nondeterministic Turing Machine {`\\(M\\)`}{" "}
                    for which {`\\(x \\in L\\)`} implies that an odd number of
                    the computation paths accept, and {`\\(x \\notin L\\)`}{" "}
                    implies that an even number of the computation paths accept.
                  </p>
                </li>
              </ol>
              <p>
                Next, I’m going to describe the notion of Turing Machines with
                Oracles, and the specific classes of problems they solve. For
                instance, consider the classes {`\\(\\text{NP}^A\\)`},{" "}
                {`\\(\\text{BPP}^A\\)`}, {`\\((\\oplus \\text{P})^A\\)`}. These
                are the classes obtained by replacing the polynomial-time
                nondeterministic Turing Machine {`\\(M\\)`} in the definitions
                above with a polynomial-time nondeterministic oracle Turing
                Machine {`\\(M\\)`} that is equipped with language {`\\(A\\)`}{" "}
                as its oracle. As usual, if we write {`\\(C\\)`} instead of{" "}
                {`\\(A\\)`} in the exponent, for some complexity class{" "}
                {`\\(C\\)`}, we mean that any language {`\\(A \\in C\\)`} is
                permitted as the oracle. If {`\\(C\\)`} has a complete language
                (as {`\\(\\text{NP}^A\\)`} and {`\\((\\oplus \\text{P})^A\\)`}{" "}
                do, for any oracle {`\\(A\\)`}), then by using that language as
                the oracle we can solve any instance of a problem in {`\\(C\\)`}{" "}
                with a single call to this specific oracle.
              </p>
              <p>
                Finally, I’m going to describe the polynomial-time hierarchy.
                Define{" "}
                {`\\(\\Delta_0^P := \\Sigma_0^P := \\Pi_0^P:= \\text{P}\\)`},
                where {`\\(\\text{P}\\)`} is the set of problems that can be
                solved in polynomial time (with respect to the size of the
                instance of the problem). Then, for all {`\\(i \\geq 0\\)`},
                define:
              </p>
              <p>
                {`$$\\Delta_{i+1}^P:=\\text{P}^{\\Sigma_i^P}$$`}{" "}
                {`$$\\Sigma_{i+1}^P:=\\text{NP}^{\\Sigma_i^P}$$`}{" "}
                {`$$\\Pi_{i+1}^P:=\\text{coNP}^{\\Sigma_i^P}$$`}
              </p>
              <p>The polynomial-time hierarchy is defined as</p>
              <p>{`$$PH = \\bigcup\\limits_{i} \\{\\Delta_i^P, \\Sigma_i^P, \\Pi_i^P\\}$$`}</p>
              <p>
                The result that I want to show here is that every language in
                the polynomial hierarchy can be solved by Turing Machines of the
                counting complexity class {`\\(\\text{P}^{\\#\\text{P}}\\)`}{" "}
                (pronounced P sharp-P), which really illuminates the
                significance of counting as a fundamental computational
                resource.
              </p>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 1: Generalization of the Valiant-Vazirani Theorem to
                      show that{" "}
                      {`\\(\\text{NP}^A \\subseteq \\text{BPP}^{(\\bigoplus P^A)}\\)`}{" "}
                      for any oracle {`\\(A\\)`}.
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The generalized Valiant-Vazirani theorem states that for
                      any {`\\(n\\)`}, there is a randomized procedure that runs
                      in {`\\(poly(n)\\)`} time and outputs a {`\\(poly(n)\\)`}
                      -size circuit {`\\(C\\)`} such that for each subset{" "}
                      {`\\(T\\subseteq\\{0,1\\}^n\\)`} if {`\\(|T|>0\\)`}, then
                    </p>

                    <p>{`$$\\Pr[|\\{x:x\\in T \\text{ and }C(x) = 1\\}|=1] \\geq \\frac{1}{8n}$$`}</p>

                    <p>
                      This lemma seeks to show that the generalized
                      Valiant-Vazirani theorem implies that for every oracle{" "}
                      {`\\(A\\)`}, that{" "}
                      {`\\(\\text{NP}^A \\subseteq \\text{BPP}^{(\\bigoplus P^A)}\\)`}
                      .
                    </p>

                    <p>
                      Let {`\\(L\\)`} be an arbitrary language in{" "}
                      {`\\(\\text{NP}^A\\)`} that is decided by a
                      nondeterministic oracle turing machine {`\\(M\\)`} that
                      runs in polynomial time. Then, given an input {`\\(x\\)`}{" "}
                      we can decide whether it is in {`\\(L\\)`} by using a
                      probabilistic turing machine oracle equipped with the
                      polynomial-time nondeterministic Turing Machine oracle{" "}
                      {`\\(\\oplus P^A\\)`}. Let {`\\(P\\)`} be the list of all
                      the computation paths of {`\\(M\\)`} upon input{" "}
                      {`\\(x\\)`} such that for all {`\\(p \\in T, p\\)`} ends
                      in an accept state and let the count of the number of
                      accepting computation paths be {`\\(m\\)`}. Using lemma
                      5.1, produce separate circuits {`\\(C_i\\)`} for each
                      computation path {`\\(p_i \\in P\\)`}, such that{" "}
                      {`\\(C_i(p_i) = 1\\)`}. Then, a nondeterministic turing
                      machine oracle {`\\(M_i\\)`} would guess {`\\(x\\)`} such
                      that{" "}
                      {`\\(\\{x: x \\in T \\text{ and } C_i(x) = 1\\}_{i=1,...,k}\\)`}
                      . Then, {`\\(M_i\\)`} would verify that the guess belongs
                      in the set by using an oracle {`\\(A\\)`} in polynomial
                      time and returns ACCEPT if the verification is successful.
                      Therefore, {`\\(M_i^A \\in \\oplus \\text{P}^A\\)`}.
                    </p>

                    <p>
                      Therefore, using {`\\(M_i^A\\)`} as an oracle, simulate
                      the {`\\(\\text{BPP}\\)`} behavior on a probabilistic
                      turing machine by calling{" "}
                      {`\\(\\text{M}_i^A, \\forall i, 1 \\leq i \\leq k\\)`} and
                      counting the number of ACCEPTS returned from the oracle:
                    </p>

                    <p>
                      Then, as with most fairly natural constructions of these
                      kinds of machines, note that if the number of ACCEPTS is
                      odd, then the probabilistic TM returns ACCEPT. If number
                      of ACCEPTS is even, then the probabilistic TM returns
                      REJECT.
                    </p>

                    <p>
                      To show that this procedure maps “yes” to “yes”: Note that
                      if {`\\(x \\in L \\in \\text{NP}^A\\)`}, then:
                    </p>

                    <p>{`$$𝑃(|\\{x:x\\in T \\text{ and }C_i(x)=1\\}_i|=1) \\geq \\frac{1}{8m}$$`}</p>

                    <p>
                      Therefore, for {`\\(k\\)`} circuits, the probability of
                      all of them deciding incorrectly becomes:
                    </p>

                    <p>{`$$\\leq \\bigg(1-\\frac{1}{8m}\\bigg)^k \\approx 1 - \\frac{k}{8m} \\quad \\text{(by taylor expansion)}$$`}</p>

                    <p>
                      For this to be in {`\\(\\text{BPP}\\)`}, the probability
                      of incorrectly deciding a string in {`\\(L\\)`} should be
                      atmost {`\\(\\frac{1}{3}\\)`}. Therefore, we can choose
                      the number of circuits, {`\\(k\\)`}, that the{" "}
                      {`\\(\\text{BPP}\\)`} would have to make to satisfy this
                      property.
                    </p>

                    <p>{`$$1−\\frac{k}{8m} \\leq \\frac{1}{3} \\Rightarrow \\frac{2}{3} \\leq \\frac{k}{8m} \\Rightarrow k \\geq \\frac{16}{3} m$$`}</p>

                    <p>
                      If {`\\(k \\geq \\frac{16}{3} m\\)`}, the probability of
                      incorrectly deciding it is atmost {`\\(\\frac{1}{3}\\)`}.
                      So, the probability of correctly deciding it is atleast{" "}
                      {`\\(\\frac{2}{3}\\)`}. Therefore, “yes” maps to “yes”.
                    </p>

                    <p>
                      To show that this procedure maps “no” to “no” (completing
                      the isomorphism of the reduction): If{" "}
                      {`\\(x \\notin L\\)`}, then the number of accepting
                      configurations in {`\\(\\oplus \\text{P}^A\\)`} will be{" "}
                      {`\\(0\\)`} which is even. Therefore, the probabilistic
                      turing machine will return a REJECT. So, “no” maps to
                      “no”. Therefore, we now have that for all {`\\(A\\)`}:
                    </p>

                    <p>{`$$\\text{NP}^A ⊆ \\text{BPP}^{(⨁ \\text{P}^A)}$$`}</p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 2: For any oracle A,{" "}
                      {`\\(\\text{NP}^A \\subseteq \\text{BPP}^A \\Rightarrow \\text{NP}^{\\text{NP}^{\\text{NP}^{(...^{\\text{NP}^A})}}} \\subseteq \\text{BPP}^A\\)`}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <strong>
                        <u>Sub-Lemma 2.1</u>:{" "}
                        {`\\(\\text{NP}^{\\text{BPP}} \\subseteq \\text{BPP}^{\\text{NP}}\\)`}
                      </strong>
                    </p>

                    <p>
                      Given any polynomial-time nondeterministic turing machine{" "}
                      {`\\(M\\)`} that queries oracle{" "}
                      {`\\(\\text{A} \\in \\text{BPP}\\)`}, we can decide a
                      string of the language using a {`\\(\\text{BPP}\\)`}{" "}
                      probabilistic turing machine {`\\(M’\\)`} that queries
                      oracle {`\\(\\text{B} \\in \\text{NP}\\)`}. Given an input{" "}
                      {`\\(x\\)`}, there are{" "}
                      {`\\(2^{|x|^{𝑂(1)}} = 2^{|x|^k}\\)`} possible computation
                      paths that are generated by {`\\(M\\)`}.
                    </p>

                    <p>
                      Therefore, let the {`\\(\\text{BPP}\\)`} machine{" "}
                      {`\\(M’\\)`} query its {`\\(\\text{NP}\\)`} turing machine
                      oracle {`\\(2^{|x|^k}\\)`} times (one time for each
                      computation path). On each query let {`\\(M’\\)`} build up
                      an array of size {`\\(|x|\\)`} of “true” or “false” for
                      each path such that if the oracle returns “true” for every
                      bit, then {`\\(M\\)`} returns “true”.
                    </p>

                    <p>
                      Then, through repeated error reduction, we can reduce the
                      error of the {`\\(\\text{BPP}\\)`} machine to atmost{" "}
                      {`\\(\\frac{1}{3|x|^k} \\times \\frac{1}{2^{|x|^k}}\\)`}
                    </p>

                    <p>
                      Therefore, upon the {`\\(2^{|x|^k}\\)`} computational
                      paths that a nondeterministic turing machine would take,
                      where the number of queries to the oracle are atmost{" "}
                      {`\\(|x|^k\\)`}, we have that the probability of
                      incorrectly deciding the string becomes:{" "}
                      {`$$\\leq \\frac{1}{3|x|^k \\times 2^{|x|^k}} \\times (2^{|x|^k} \\times |x|^k) = \\frac{1}{3}$$`}
                    </p>

                    <p>
                      It follows then that the probability of correctly deciding
                      the string is atleast {`\\(\\frac{2}{3}\\)`}. Since this
                      is simulated on a {`\\(\\text{BPP}\\)`} machine with a
                      turing machine oracle in {`\\(\\text{NP}\\)`}, we have
                      that
                    </p>

                    <p>{`$$\\text{L} \\in \\text{BPP}^{\\text{NP}}$$`}</p>

                    <p>Therefore, we conclude that:</p>

                    <p>{`$$\\text{NP}^{\\text{BPP}} ⊆ \\text{BPP}^{\\text{NP}}$$`}</p>

                    <p>QED.</p>

                    <p>
                      <strong>
                        <u>Sub-Lemma 2.2</u>:{" "}
                        {`\\(\\text{BPP}^{\\text{BPP}} \\subseteq \\text{BPP}\\)`}
                      </strong>
                    </p>

                    <p>
                      Given a probabilistic turing machine {`\\(M\\)`} that
                      queries oracle {`\\(A \\in \\text{BPP}\\)`}, we can decide
                      a string of the language by using a {`\\(\\text{BPP}\\)`}{" "}
                      probabilistic turing machine {`\\(M'\\)`}, and we can use
                      error reduction to show that {`\\(L \\in \\text{BPP}\\)`}{" "}
                      by simulating the oracle, where the probability of errors
                      for {`\\(M'\\)`} is {`\\(e_2\\)`} and the probability of
                      errors for {`\\(M\\)`} is {`\\(𝑒_1\\)`}.
                    </p>

                    <p>
                      Given an input {`\\(x\\)`}, query the oracle atleast once
                      on each bit of {`\\(x\\)`}. Therefore, the total number of
                      queries would be {`\\(|x|^k\\)`}. Hence, the probability
                      of getting an error on a single query is {`\\(e_1\\)`}{" "}
                      whereas the probability of getting an error in the entire
                      simulation would be {`\\(|x|^k e_1\\)`}. Then, adding in
                      the probability of getting an error in {`\\(M\\)`}, we
                      have that the probability of error is:{" "}
                      {`\\(|x|^k e_1 + e_2\\)`}.
                    </p>

                    <p>
                      Through repeated error reduction, we can reduce the values
                      of {`\\(e_1\\)`} and {`\\(ee_2\\)`} from{" "}
                      {`\\(\\frac{1}{3}\\)`}. However, to show that{" "}
                      {`\\(x \\in \\text{BPP}\\)`}, we would need:
                    </p>

                    <p>{`$$|x|^k e_1 + e_2 \\leq \\frac{1}{3}$$`}</p>

                    <p>
                      Note that this is satisfied if we do repeated error
                      reduction as many times as needed until:
                    </p>

                    <p>{`$$e_1= \\frac{1}{6|x|^k},\\quad \\quad e_2=\\frac{1}{6},$$`}</p>

                    <p>since</p>

                    <p>{`$$|x|^k e_1 + e_2 = \\frac{1}{6} + \\frac{1}{6} = \\frac{1}{3}.$$`}</p>

                    <p>
                      If the probability of an error is {`\\(\\frac{1}{3}\\)`},
                      then the probability of success will be{" "}
                      {`\\(\\frac{2}{3}\\)`}. Therefore, through simulating the{" "}
                      {`\\(\\text{BPP}\\)`} oracle on a {`\\(\\text{BPP}\\)`}{" "}
                      machine {`\\(M\\)`}, we have that:
                    </p>

                    <p>{`$$\\text{BPP}^{\\text{BPP}} \\subseteq \\text{BPP}$$`}</p>

                    <p>QED.</p>

                    <p>
                      Given lemma 1,{" "}
                      {`\\((\\text{NP}^{\\text{BPP}} ⊆ \\text{BPP}^{\\text{NP}})\\)`}{" "}
                      and lemma 2{" "}
                      {`\\((\\text{BPP}^{\\text{BPP}} ⊆ \\text{BPP})\\)`}, we
                      can finally use induction to show that:
                    </p>

                    <p>{`$$\\text{NP}^A ⊆ \\text{BPP}^A ⇒ \\text{NP}^{(\\text{NP}^{(\\text{NP}^{(...^{(\\text{NP}^A)}... )))}}} ⊆ \\text{BPP}^A,$$`}</p>

                    <p>
                      where we induct on the height, {`\\(i\\)`}, of the{" "}
                      {`\\(NP\\)`} tower.
                    </p>

                    <p>
                      <u>Base Case</u>: {`\\(i = 1\\)`}: When {`\\(i = 1\\)`},
                      the formulation becomes{" "}
                      {`\\(\\text{NP}^A ⊆ \\text{BPP}^A\\)`}, which is known to
                      be true.
                    </p>

                    <p>
                      <u>Assumptive Case</u>: {`\\(i = k\\)`} Assume that for
                      height {`\\(k\\)`},{" "}
                      {`$$\\text{NP}^{(\\text{NP}^{(...^{(\\text{NP}^A)))}}} ⊆ \\text{BPP}^A$$`}
                    </p>

                    <p>
                      <u>Inductive Case</u>: {`\\(i = k + 1\\)`} Expanding to
                      the next level, we get:
                    </p>

                    <p>{`$$\\text{NP}^{\\text{NP}^{(\\text{NP}(...^{(\\text{NP}^A)))}}} ⊆ \\text{NP}^{\\text{BPP}^A}$$`}</p>

                    <p>
                      Applying lemma 1{" "}
                      {`\\((\\text{NP}^{\\text{BPP}} ⊆ \\text{BPP}^{\\text{NP}})\\)`}{" "}
                      to the RHS yields:
                    </p>

                    <p>{`$$\\text{NP}^{\\text{NP}^{(\\text{NP}(...^{(\\text{NP}^A)))}}} ⊆ \\text{BPP}^{\\text{NP}^A}$$`}</p>

                    <p>
                      We know that {`\\(\\text{NP}^A ⊆ \\text{BPP}^A\\)`}.
                      Therefore, the expression becomes:
                    </p>

                    <p>{`$$\\text{NP}^{\\text{NP}^{(\\text{NP}(...^{(\\text{NP}^A)))}}} \\subseteq \\text{BPP}^{\\text{BPP}^A}$$`}</p>

                    <p>
                      Applying lemma 2{" "}
                      {`\\((\\text{BPP}^{\\text{BPP}} ⊆ \\text{BPP})\\)`} to the
                      RHS yields:
                    </p>

                    <p>{`$$\\text{NP}^{\\text{NP}^{(\\text{NP}(...^{(\\text{NP}^A)))}}} \\subseteq \\text{BPP}^A$$`}</p>

                    <p>
                      Therefore, the inductive stage holds for{" "}
                      {`\\(i = k + 1\\)`}.
                    </p>

                    <p>
                      <u>Conclusive Step:</u>
                    </p>

                    <p>
                      The theorem holds for an {`\\(\\text{NP}\\)`} tower of
                      height {`\\(k + 1\\)`} provided that it holds for an{" "}
                      {`\\(\\text{NP}\\)`} tower of height {`\\(k\\)`}. However,
                      from our basis step, we showed that the theorem holds for
                      an {`\\(\\text{NP}\\)`} tower of height {`\\(1\\)`}.
                      Therefore, from the inductive hypothesis, the theorem
                      therefore holds true for any {`\\(\\text{NP}\\)`} tower of
                      height {`\\(𝑛 \\in \\mathbb{N}\\)`}.
                    </p>

                    <p>Hence, we have that:</p>

                    <p>{`$$\\text{NP}^A ⊆ \\text{BPP}^A \\Rightarrow \\text{NP}^{\\text{NP}^{(\\text{NP}(...^{(\\text{NP}^A)))}}} ⊆ \\text{BPP}^A$$`}</p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 3: {`\\(\\text{co}\\)`}-
                      {`\\((\\oplus P) \\subseteq \\oplus P\\)`}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Given an arbitrary language {`\\(L \\in \\text{co}\\)`}-
                      {`\\((\\oplus \\text{P})\\)`} that is decided by a
                      nondeterministic polynomial-time turing machine{" "}
                      {`\\(M(x, y)\\)`} s.t. {`\\(x \\in L\\)`} implies that an
                      even number of computation paths ({`\\(y\\)`} is even) end
                      up on an ACCEPT state and {`\\(x \\notin L\\)`} implies
                      that an odd number of computation paths ({`\\(y\\)`} is
                      odd) end up on a REJECT state, we can also decide{" "}
                      {`\\(L\\)`} on a {`\\(\\oplus \\text{P}\\)`} machine{" "}
                      {`\\(M'\\)`}.
                    </p>

                    <p>Therefore, we use the following procedure:</p>

                    <p>{`\\(M’(x)\\)`}:</p>

                    <p>
                      If{" "}
                      {`\\(L = \\{x | \\text{ number of }y's \\text{ s. t. }M(x, 𝑦) = 1 \\text{ is even}\\}\\)`}
                      , then construct {`\\((\\oplus \\text{P})\\)`} machine{" "}
                      {`\\(M'\\)`} that runs on {`\\(M'(x, 𝑦)\\)`} Then, we
                      allow {`\\(M’(x, ty)\\)`} to have an additional
                      computational path than {`\\(M\\)`} by letting it do the
                      following: The additional computational path simulates{" "}
                      {`\\(M\\)`}. If {`\\(M\\)`} accepts, then return ACCEPT.
                      Else, REJECT. The remaining computational paths decides{" "}
                      {`\\(x\\)`} nondeterministically. It does this by choosing
                      a “yes” or “no” branch at every step of the decision tree.
                      In this case, it would only return ACCEPT if all paths
                      reached the “no” leaf. Otherwise, it would REJECT.
                    </p>

                    <p>
                      We see here that {`\\(M'\\)`} has exactly 1 more accepting
                      computation path than {`\\(M\\)`}. Since {`\\(M\\)`} had
                      an even number of paths, we have that {`\\(M’\\)`} has an
                      odd number of paths. Therefore,{" "}
                      {`\\(L = \\{ x | \\text{ number of y's s.t. } M'(x, 𝑦) =
1 \\text{ is odd}\\}\\)`}
                      . Hence, {`\\(L \\in \\oplus \\text{P}\\)`}.
                    </p>

                    <p>
                      Since{" "}
                      {`\\(L \\in \\oplus \\text{P}, L \\in \\text{co}\\)`}-
                      {`\\(\\oplus \\text{P}\\)`}, we use the transitivity
                      property to conclude that:
                    </p>

                    <p>{`$$\\text{co -}\\oplus \\text{P} ⊆ \\oplus \\text{P}$$`}</p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 4:{" "}
                      {`\\((\\oplus \\text{P})^{\\oplus \\text{P}} \\subseteq \\oplus \\text{P}\\)`}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Given an arbitrary language{" "}
                      {`\\(L \\in (\\oplus \\text{P})^{\\oplus \\text{P}}\\)`}{" "}
                      that is decided by a nondeterministic turing machine{" "}
                      {`\\(M\\)`} which queries an oracle{" "}
                      {`\\(A \\in \\oplus\\text{P}\\)`}, we can show that it is
                      also decided by a nondeterministic turing machine{" "}
                      {`\\(M'\\)`} s.t.{" "}
                      {`\\(L = \\{x: |\\{y: M'(x,y)=1\\}| \\text{ is odd}\\}\\)`}
                      .
                    </p>

                    <p>
                      First use the method suggested in the hint, knowing that
                      there exists some nondeterministic guess that produces a
                      correct transcript that concurs with {`\\(M\\)`} on the
                      specified computation path.
                    </p>

                    <p>
                      Then, we use the following strategy to decide on an input{" "}
                      {`\\(x\\)`}.
                    </p>

                    <p>
                      {`\\(M’(x)\\)`}: Guess a transcript of {`\\(M\\)`} on{" "}
                      {`\\(x\\)`}. If the transcript is not accurate, then
                      REJECT immediately. If the transcript is not an accepting
                      configuration, then return REJECT. For all accurate
                      queries (as determined by the transcript):
                      ondeterministically guess the computation path of a TM{" "}
                      {`\\(B\\)`} for {`\\(A\\)`} on each query it is sent. If
                      each of these computation paths leads to an accept state
                      in {`\\(B\\)`}, then return ACCEPT. For all inaccurate
                      queries (as determined by the transcript):
                      Nondeterministically guess the computation path of a TM{" "}
                      {`\\(B'\\)`} for {`\\(\\bar{A}\\)`}) on each query it is
                      sent. If each of these computation paths leads to an
                      accept state in {`\\(B'\\)`}, then also return ACCEPT.
                    </p>

                    <p>
                      Therefore, we claim that this nondeterministic
                      polynomial-time turing machine {`\\(M'\\)`} described
                      above can decide {`\\(x\\)`}. To prove this, we show that
                      “yes” maps to “yes” and “no” maps to “no” (thus forming a
                      bijection/isomorphism between the two instances).
                    </p>

                    <p>
                      To show that “yes” maps to “yes”: The transcript that was
                      guessed would let {`\\(M'\\)`} guess {`\\(\\chi\\)`}{" "}
                      accepting computation paths. {`\\(\\chi\\)`} is the total
                      number of paths that can be taken. There are an odd number
                      of computation paths for queries that the transcript says
                      are accurate. There are an odd number of computation paths
                      for queries that the transcript says are not accurate. So:
                    </p>

                    <p>{`$$𝜒 = (2k + 1)(2\\ell + 1) = 4k\\ell + 2\\ell + 2k + 1 = 2(2k\\ell + \\ell + k) + 1 \\in \\text{ odd }$$`}</p>

                    <p>
                      Therefore, we have that “yes” instances of {`\\(L\\)`}{" "}
                      will get mapped to “yes” by {`\\(M'\\)`}.
                    </p>

                    <p>
                      To show that “no” maps to “no”: If the instance is “no”,
                      then: 1) The transcript guessed would not be accurate
                    </p>

                    <ol start="2" type="1">
                      <li>
                        <p>
                          The transcript guessed would not be an accepting
                          configuration.
                        </p>
                      </li>
                      <li>
                        <p>
                          The number of computation paths for accurate queries /
                          inaccurate queries are even.
                        </p>
                      </li>
                    </ol>

                    <p>
                      4)The number of accepting configurations are not even
                      either
                    </p>

                    <p>
                      The procedure outlined above, however, ensures that{" "}
                      {`\\(M'\\)`} REJECTs on all of these possibilities.
                    </p>

                    <p>
                      Since we have shown that “yes” maps to “yes” and “no” maps
                      to “no”, we conclude the proof of correctness for this
                      procedure, and therefore we have that:
                    </p>

                    <p>{`$$L \\in \\oplus \\text{P}$$`}</p>

                    <p>Then, by the property of transitivity, we have that:</p>

                    <p>{`$$(\\oplus \\text{P})^{\\oplus \\text{P}} \\subseteq \\oplus \\text{P}$$`}</p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 5:{" "}
                      {`\\(\\text{PH} \\subseteq \\text{BPP}^{\\oplus \\text{P}}\\)`}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Let {`\\(L\\)`} be an arbitrary language such that{" "}
                      {`\\(L \\in \\text{PH}\\)`}, where
                    </p>

                    <p>{`$$ \\text{PH} = \\bigcup\\limits_{k \\in \\mathbb{N}} \\Sigma_k^P$$`}</p>

                    <p>
                      It follows then that we need to show that{" "}
                      {`\\(\\forall k, Σ_k^𝑃 ⊆ BPP^{\\bigoplus \\text{P}}\\)`}.
                      Therefore,
                    </p>

                    <p>{`$$\\text{NP}^A ⊆ \\text{BPP}^{(\\oplus \\text{P}^A)} \\quad \\text{(from Lemma 1)}$$`}</p>

                    <p>
                      Let {`\\(A \\in \\oplus \\text{P}\\)`}. It follows then
                      that since{" "}
                      {`\\((\\oplus \\text{P})^{\\oplus \\text{P}} ⊆ \\oplus \\text{P}\\)`}{" "}
                      (proven in Lemma 2), we can simply replace{" "}
                      {`\\(\\oplus \\text{P}^A\\)`} with some language{" "}
                      {`\\(A' \\in \\oplus \\text{P}\\)`}. Therefore, we have
                      that:
                    </p>

                    <p>{`$$\\text{NP}^A ⊆ \\text{BPP}^{A'}$$`}</p>

                    <p>
                      Then, let {`\\(B \\in \\oplus \\text{P}\\)`}-complete. It
                      follows then that since {`\\(B\\)`} can be reduced to{" "}
                      {`\\(A\\)`}, {`\\(A'\\)`} , we can rewrite the above set
                      inclusion as:
                    </p>

                    <p>{`$$\\text{NP}^B ⊆ \\text{BPP}^B$$`}</p>

                    <p>
                      Given the above set-inclusion to be true, we also know
                      that:
                    </p>

                    <p>
                      {`$$\\text{NP}^{\\text{NP}^B} \\subseteq \\text{BPP}^B$$`}{" "}
                      {`$$\\text{NP}^{\\text{NP}^{\\text{NP}^B}} \\subseteq \\text{BPP}^B$$`}{" "}
                      {`$$\\text{NP}^{\\text{NP}^{\\text{NP}^{\\text{NP}^{\\text{NP}^{...^{\\text{NP}^B}}}}}} \\subseteq \\text{BPP}^B$$`}
                    </p>

                    <p>
                      Therefore, we have that for all {`\\(k\\)`} levels in the{" "}
                      {`\\(\\text{NP}\\)`}-tower:
                    </p>

                    <p>{`$$\\{\\text{NP}^{\\text{NP}^{...^{\\text{NP}^B}}}\\} ⊆ \\text{BPP}^B$$`}</p>

                    <p>
                      However, by definition the LHS (with a height of{" "}
                      {`\\(k\\)`}) is equal to {`\\(Σ_k^P\\)`}. Therefore, we
                      now have that for all {`\\(k\\)`}, we can just construct
                      the {`\\(\\text{NP}\\)`}-tower of height {`\\(k\\)`} and
                      it would still be contained in {`\\(\\text{BPP}^B\\)`}.
                    </p>

                    <p>{`$$\\forall k, Σ_k^𝑃 \\in \\text{BPP}^B$$`}</p>

                    <p>{`$$ \\text{PH} = \\bigcup\\limits_{k \\in \\mathbb{N}} \\Sigma_k^P \\in \\text{BPP}^B$$`}</p>

                    <p>{`$$\\text{PH} \\subseteq \\text{BPP}^B, \\quad B \\in \\oplus \\text{P-complete}$$`}</p>

                    <p>
                      This then yields:{" "}
                      {`$$\\text{PH} ⊆ \\text{BPP}^{\\oplus \\text{P}}$$`}
                    </p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 6: The efficient constrution of a circuit{" "}
                      {`\\(C'\\)`} with satisfying assignments of cardinality{" "}
                      {`\\(g(|C|)\\)`}, where g is a fixed non-negative
                      polynomial and C is a fixed circuit.
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Our aim over the next 3 lemmas is to show that the ability
                      to count is sufficient to capture the entire computational
                      power of the polynomial hierarchy.
                    </p>

                    <p>
                      So, let {`\\(C\\)`} be Boolean circuit, and let{" "}
                      {`\\(g\\)`} be a polynomial with nonnegative integer
                      coefficients. The broad goal of this lemma is to describe
                      a deterministic procedure that, given {`\\(C\\)`},
                      produces a circuit {`\\(C'\\)`} such that
                    </p>

                    <p>{`$$|\\{y: C'(y) = 1\\}| = g(|\\{x: C(x) = 1\\}|)$$`}</p>

                    <p>
                      Additionally, this construction should run in
                      polynomial-time in the size of {`\\(C\\)`} and use space
                      atmost in the size of {`\\(g\\)`}, when it is encoded in
                      the natural way as a vector of coefficients.
                    </p>

                    <p>
                      <u>Sub-Lemma 6.1</u>: Given two circuits {`\\(A\\)`} and{" "}
                      {`\\(B\\)`} with {`\\(\\alpha\\)`} and {`\\(\\beta\\)`}{" "}
                      satisfying assignments respectively, we can produce a
                      circuit {`\\(C\\)`} with {`\\(\\alpha + \\beta\\)`}{" "}
                      satisfying assignments:
                    </p>

                    <p>
                      We first start by examining the result when{" "}
                      {`\\(C = A \\vee B\\)`}.
                    </p>

                    <table class="table_center">
                      <tr>
                        <th>A</th>
                        <th>B</th>
                        <th>C</th>
                      </tr>
                      <tr>
                        <th>0</th>
                        <th>0</th>
                        <th>0</th>
                      </tr>
                      <tr>
                        <th>0</th>
                        <th>1</th>
                        <th>1</th>
                      </tr>
                      <tr>
                        <th>1</th>
                        <th>0</th>
                        <th>1</th>
                      </tr>
                      <tr>
                        <th>1</th>
                        <th>1</th>
                        <th>1</th>
                      </tr>
                    </table>

                    <p>
                      However, noting that A and B might have inputs of
                      differing dimensions: The total number of satisfying
                      assignments when A and B are combined simply using the OR
                      operator is:
                    </p>

                    <p>{`$$\\#\\mathrm{SAT}(C) = \\alpha\\beta + \\alpha(2^{dim(A)} −\\beta) + \\beta(2^{dim(B)} − \\alpha) \\neq \\alpha + \\beta$$`}</p>

                    <p>
                      On the other hand, when {`\\(C = A \\oplus B\\)`}, where{" "}
                      {`\\(dim(A) = dim(B) = 1\\)`}:
                    </p>

                    <table class="table_center">
                      <tr>
                        <th>A</th>
                        <th>B</th>
                        <th>C</th>
                      </tr>
                      <tr>
                        <th>0</th>
                        <th>0</th>
                        <th>0</th>
                      </tr>
                      <tr>
                        <th>0</th>
                        <th>1</th>
                        <th>1</th>
                      </tr>
                      <tr>
                        <th>1</th>
                        <th>0</th>
                        <th>1</th>
                      </tr>
                      <tr>
                        <th>1</th>
                        <th>1</th>
                        <th>0</th>
                      </tr>
                    </table>

                    <p>{`$$\\#\\mathrm{SAT}(C) = \\alpha + \\beta$$`}</p>

                    <p>
                      Therefore, we then try the following approach (letting{" "}
                      {`\\(dim(A) = d_A\\)`}, {`\\(dim(B) = d_B\\)`}):
                    </p>

                    <table class="table_center">
                      <tr>
                        <th>{`\\(d_A\\)`}</th>
                        <th>
                          If the first {`\\(d_A\\)`} bits are true and the last{" "}
                          {`\\(d_B\\)`} bits satisfy {`\\(B\\)`}, then return
                          true.
                        </th>
                      </tr>
                      <tr>
                        <th>{`\\(d_B\\)`}</th>
                        <th>
                          If the first {`\\(d_A\\)`} bits satisfy {`\\(A\\)`}{" "}
                          and the last {`\\(d_B\\)`} bits are true, then return
                          true.
                        </th>
                      </tr>
                    </table>

                    <p>
                      The resultant circuit produces {`\\(\\alpha + \\beta\\)`}{" "}
                      satisfying instances in all cases except when {`\\(A\\)`}{" "}
                      and {`\\(B\\)`} are both satisfying instances (here the
                      resultant circuit produces {`\\(\\alpha + \\beta − 1\\)`}{" "}
                      satisfying instances).
                    </p>

                    <p>
                      Therefore, in this case we can then attempt a workaround
                      by introducing a dummy variable {`\\(\\psi\\)`} that
                      negates both the instances when{" "}
                      {`\\(A = B = \\text{ True}\\)`}.
                    </p>

                    <p>Therefore, we let:</p>

                    <p>{`$$C = (¬\\psi \\wedge \\tilde{A}) \\vee (\\psi \\wedge \\tilde{B}),$$`}</p>

                    <p>
                      where {`\\(\\tilde{A}\\)`} is the circuit that checks
                      satisfiability of the first {`\\(d_A\\)`} assignments to
                      the {`\\(A\\)`} circuit and checks that the remaining bits
                      are all TRUE (by ANDing them), and where{" "}
                      {`\\(\\tilde{B}\\)`} is the circuit that checks that the
                      first {`\\(d_A\\)`} assignments are all TRUE (by ANDing
                      them), and that the remaining {`\\(d_B\\)`} bits are
                      satisfying assignments to the {`\\(B\\)`} circuit.
                    </p>

                    <p>
                      Therefore, the negated Boolean variable {`\\(\\psi\\)`} in
                      one of the clauses is then able to add the additional
                      count for when {`\\(A\\)`} and {`\\(B\\)`} are true.
                    </p>

                    <p>QED.</p>

                    <p>
                      <u>Sub-Lemma 6.2</u>: Given two circuits {`\\(A\\)`} and{" "}
                      {`\\(B\\)`} with {`\\(\\alpha\\)`} and {`\\(\\beta\\)`}{" "}
                      satisfying assignments respectively, we can produce a
                      circuit {`\\(C\\)`} with {`\\(\\alpha\\beta\\)`}{" "}
                      satisfying assignments:
                    </p>

                    <p>
                      We claim that {`\\(C = A \\wedge B\\)`}. To prove that{" "}
                      {`\\(C\\)`} yields {`\\(\\alpha\\beta\\)`} satisfying
                      assignments, let {`\\(\\tilde{A}\\)`} be the set of
                      satisfying assignments of {`\\(A\\)`}, and let{" "}
                      {`\\(\\tilde{B}\\)`} be the set of satisfying assignments
                      of {`\\(B\\)`}. Therefore, the cardinality of the set of
                      tuples{" "}
                      {`\\(\\tilde{A} × \\tilde{B} = |\\tilde{A}| × |\\tilde{B}|\\)`}
                      .
                    </p>

                    <p>The onus is then to prove that:</p>

                    <ol type="1">
                      <li>
                        {`\\(\\forall x \\in \\tilde{A} × \\tilde{B}, x\\)`} is
                        a satisfying assignment of {`\\(C\\)`}:
                      </li>
                    </ol>

                    <p>
                      If {`\\(\\forall x \\in \\tilde{A} × \\tilde{B}\\)`}, then{" "}
                      {`\\(x\\)`} consists of a (satisfying, satisfying)
                      assignment, one from {`\\(\\tilde{A}\\)`} and another from{" "}
                      {`\\(\\tilde{B}\\)`}. Therefore, {`\\(x\\)`} will clearly
                      satisfy an AND gate which takes as input any possible
                      value of {`\\(x\\)`}. Hence, {`\\(x\\)`} is a satisfying
                      assignment of {`\\(C\\)`}.
                    </p>

                    <ol start="2" type="1">
                      <li>
                        {`\\(\\forall x \\text{ s.t. } C(x)=1, x\\in\\tilde{A} × \\tilde{B}\\)`}
                        :
                      </li>
                    </ol>

                    <p>
                      If {`\\(C(x) = 1\\)`}, then it must be in{" "}
                      {`\\(\\tilde{A}\\times\\tilde{B}\\)`}. This is because
                      {`\\(\\tilde{A}\\)`} and {`\\(\\tilde{B}\\)`} contain all
                      the satisfying assignments for {`\\(A\\)`} and {`\\(B\\)`}{" "}
                      respectively.
                    </p>

                    <p>Therefore, {`\\(x \\in \\tilde{A} × \\tilde{B}\\)`}.</p>

                    <p>
                      Therefore, {`\\(C = A ∧ B\\)`} will have{" "}
                      {`\\(\\alpha\\beta\\)`} satisfying assignments where{" "}
                      {`\\(A\\)`} has {`\\(\\alpha\\)`} satisfying assignments
                      and {`\\(B\\)`} has {`\\(\\beta\\)`} satisfying
                      assignments.
                    </p>

                    <p>QED.</p>

                    <p>
                      <u>Sub-Lemma 6.3</u>: We can construct a circuit C with t
                      satisfying assignments: We create a circuit {`\\(T\\)`}{" "}
                      that has {`\\(t_i\\)`} satisfying assignments by noting
                      that we can just return TRUE if the bit-value{" "}
                      {`\\(< t_i\\)`}. The minimum number of bits necessary here
                      would be equivalent to the minimum number of bits
                      necessary to represent {`\\(t_i\\)`} which would be{" "}
                      {`\\(log(t_i)\\)`}.
                    </p>

                    <p>
                      Therefore, to now prove the main lemma, given a circuit{" "}
                      {`\\(C\\)`} with {`\\(c\\)`} satisfying assignments and a
                      positive-coefficient polynomial{" "}
                      {`\\(g:\\mathbb{N} \\rightarrow\\mathbb{N}\\)`}, we can
                      construct a deterministic procedure that simply produces a
                      circuit {`\\(C'\\)`} with {`\\(g(c)\\)`} satisfying
                      assignments. The polynomial {`\\(g\\)`} is:
                    </p>

                    <p>{`$$ g(x) = \\sum\\limits_{i=0} t_i x^i$$`}</p>

                    <p>
                      To evaluate the circuit and the lemmas on this polynomial
                      (the wording here is intentional), we need to start by
                      finding {`\\(x_i\\)`}, then using that to find{" "}
                      {`\\(t_i x^i\\)`} and then adding that{" "}
                      {`\\(\\forall i's\\)`}:
                    </p>

                    <p>
                      For each {`\\(i\\)`}, we can create a circuit{" "}
                      {`\\(C_{i_1}\\)`} with {`\\(c^i\\)`} assignments by
                      AND’ing the existing circuit to {`\\(C\\)`} {`\\(i\\)`}{" "}
                      times (using lemma 6.2).
                    </p>

                    <p>
                      We then create a circuit {`\\(T\\)`} that has {`\\(t\\)`}{" "}
                      satisfying assignments by using lemma 6.3.
                    </p>

                    <p>
                      Then, by lemma 6.2, we have that the {`\\(i\\)`}’th term
                      in the polynomial would be represented by a circuit{" "}
                      {`\\(C_{i_2} = C_{i_1} \\text{ AND } T_i\\)`}.
                    </p>

                    <p>
                      Finally, we perform lemma 6.1 on{" "}
                      {`\\(C_{i_2}, \\forall i\\)`} to produce {`\\(C'\\)`} with{" "}
                      {`\\(Σ_{i=0}^{deg(g)}[\\#\\text{SAT}(C_{i_2})] = g(c)\\)`}{" "}
                      satisfying assignments.
                    </p>

                    <p>Then, return {`\\(C'\\)`}.</p>

                    <p>
                      Finally, the onus of the proof is to show that the
                      run-time of the procedure is in the size of {`\\(C\\)`}{" "}
                      and {`\\(g\\)`}:
                    </p>

                    <p>
                      Everytime lemmas 6.1, 6.2, and 6.3 are applied, an{" "}
                      {`\\(𝑂(|C|)\\)`}, {`\\(𝑂(1)\\)`} and{" "}
                      {`\\(𝑂(\\log t_i)\\)`} number of gates are added (each of
                      which can be added in {`\\(𝑂(1)\\)`} time) respectively.
                      Therefore, the total number of gates that would need to be
                      added throughout the entire procedure would be atmost:
                    </p>

                    <p>{`$$T(g, C) \\leq 𝑂(|C|)(\\text{deg}(g) + 𝑂(1)) \\text{deg}(g) + \\max\\limits_i 𝑂(\\log t_i)$$`}</p>

                    <p>{`$$T(g, C) \\leq 𝑂(|C|)\\text{deg}(g) + 𝑂(|C|) + \\max\\limits_i 𝑂(\\log t_i) = \\text{poly}(|C|, g) i$$`}</p>

                    <p>
                      Therefore, the runtime of this procedure is in the size of{" "}
                      {`\\(C\\)`}, and takes space atmost {`\\(g\\)`}. Hence, we
                      now have a procedure that produces a circuit {`\\(C'\\)`}{" "}
                      given {`\\(C\\)`} such that:
                    </p>

                    <p>{`$$|{𝑦: C'(𝑦) = 1}| = g(|{x: C(x) = 1}|)$$`}</p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 7: Constructing an efficiently computable
                      non-negative polynomial {`\\(g(t)\\)`} for which{" "}
                      {`\\(t\\equiv k\\mod 2 \\Rightarrow g(t) \\equiv k \\mod 2^m\\)`}
                      , where {`\\(m\\)`} is a power of 2.
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The purpose of this lemma is to create a deterministic
                      procedure that, given {`\\(m\\)`} a power of two, outputs
                      (as a sequence of coefficients) a polynomial{" "}
                      {`\\(g: \\mathbb{Z} \\to \\mathbb{Z}\\)`} with nonnegative
                      integer coefficients for which{" "}
                      {`$$t \\equiv 0 \\mod 2 \\Rightarrow g(t) \\equiv 0 \\mod 2^m$$`}{" "}
                      {`$$t \\equiv 1 \\mod 2 \\Rightarrow g(t) \\equiv 1 \\mod 2^m$$`}{" "}
                      and that runs in time {`\\(poly(m)\\)`}.
                    </p>

                    <p>
                      <u>Sub-Lemma 7.1</u>:{" "}
                      {`\\(t\\equiv 0\\mod 2^{2^i} \\Rightarrow g_0(t) \\equiv 0 \\mod 2^{2^{i+1}}\\)`}
                      , where {`\\(g_0(t)=3t^2 - 2t^3\\)`}:
                    </p>

                    <p>
                      Given that {`\\(g_0(t) = 3t^2 − 2t^3 = t^2(3 − 2t)\\)`}{" "}
                      and that {`\\(t \\equiv 0 \\mod 2^{2^i}\\)`}, we let{" "}
                      {`\\(t = k\\cdot 2^{2^i}, k \\in \\mathbb{N}\\)`}. It
                      follows then that:{" "}
                      {`$$g_0(t) = t^2(3 − 2t) = k^2 (2^{2^i})^2 (3−2(k\\cdot 2^{2^i}))$$`}{" "}
                      {`$$= 3k^2 \\cdot 2^{2^{i+1}} − 2k^3 \\cdot 2^{2×2^{i+1}}$$`}{" "}
                      {`$$=3k^2 \\cdot 2^{2^{i+1}} −8k^3 \\cdot 2^{2^{i+1}}$$`}{" "}
                      {`$$= (\\alpha + \\beta) \\cdot 2^{2^{i+!}}$$`}{" "}
                      {`$$= \\gamma \\cdot 2^{2^{i+1}}$$`}
                    </p>

                    <p>
                      Then, since{" "}
                      {`\\(\\alpha \\in \\mathbb{N}, \\beta \\in \\mathbb{N}\\)`}
                      , it follows that {`\\(\\gamma \\in \\mathbb{N}\\)`}.
                      Therefore,{" "}
                      {`$$\\gamma \\cdot 2^{2^{i+1}} \\equiv 0 \\mod 2^{2^{i+1}}$$`}{" "}
                      QED.
                    </p>

                    <p>
                      <u>Sub-Lemma 7.2</u>:{" "}
                      {`\\(t\\equiv 1 \\mod 2^{2^i} \\Rightarrow g_0(t) \\equiv 1 \\mod 2^{2^{i+1}}\\)`}
                      , where {`\\(g_0(t) = 3t^2 - 2t^3\\)`}:
                    </p>

                    <p>
                      Given that {`\\(g_0(t) = 3t^2 − 2t^3 = t^2(3 − 2t)\\)`}{" "}
                      and that {`\\(t \\equiv 1 \\mod 2^{2^i}\\)`}, we let{" "}
                      {`\\(t=k\\cdot 2^{2^i}+1\\)`}, for{" "}
                      {`\\(k \\in \\mathbb{N}\\)`}. It follows then that
                    </p>

                    <p>{`$$g_0(t) = t^2(3 − 2t)$$`}</p>

                    <p>{`$$=(1+k^2\\cdot 2^{2^{i+1}} + k\\cdot 2^{2^{i+1}})(3 - 2 - k\\cdot 2^{1+2^i})$$`}</p>

                    <p>{`$$ = 1 + 2^{2^{1+i}}k^2 − 2^{2+2^{1+i}}k^2 − 2^{1+2^i+2^{1+i}}k^3$$`}</p>

                    <p>{`$$= 1 + 2^{2^{1+i}}(−3k^2) − (1 + 2^i)k^3 2^{2^{1+i}}$$`}</p>

                    <p>{`$$= 1 + 2^{1+2^i}(-(2^i+1)k^3 - 3k^2)$$`}</p>

                    <p>
                      Then, since {`\\(k, i \\in \\mathbb{N}\\)`}, it follows
                      that:
                    </p>

                    <p>{`$$g_0(t)=1+2^{1+2^i}\\beta,\\quad \\beta\\in\\mathbb{N}$$`}</p>

                    <p>
                      Hence,{" "}
                      {`$$g_0(t)=1+\\beta\\cdot 2^{2^{i+1}} \\equiv 1 \\mod 2^{2^{i+1}}$$`}
                    </p>

                    <p>QED.</p>

                    <p>
                      Our goal is now to construct a deterministic procedure
                      that outputs the coefficients of a polynomial{" "}
                      {`\\(g: \\mathbb{Z} → \\mathbb{Z}\\)`} with nonnegative
                      integer coefficients for which:
                    </p>

                    <p>{`$$t \\equiv 0 \\mod 2 \\Rightarrow g(t) \\equiv 0 \\mod 2^m$$`}</p>

                    <p>{`$$t \\equiv 1 \\mod 2 \\Rightarrow g(t) \\equiv 1 \\mod 2^m$$`}</p>

                    <p>
                      and that runs in {`\\(𝑝𝑜𝑙𝑦(m)\\)`} where {`\\(m\\)`} is a
                      power of {`\\(2\\)`}. Therefore, let {`\\(m = 2^k\\)`},
                      for {`\\(k \\in \\mathbb{N}\\)`}. It follows then that we
                      want to construct a procedure that outputs the
                      coefficients to a polynomial{" "}
                      {`\\(g: \\mathbb{Z} \\rightarrow \\mathbb{Z}\\)`} such
                      that
                    </p>

                    <p>{`$$t \\equiv 0 \\mod 2 \\Rightarrow g(t) ≡ 0 \\mod 2^{2^k}$$`}</p>

                    <p>{`$$t ≡ 1 \\mod 2 \\Rightarrow g(t) ≡ 1 \\mod 2^{2^k}$$`}</p>

                    <p>
                      To do so, we recall lemmas 7.1 and 7.2 with{" "}
                      {`\\(g_0(t) = 3t^2 − 2t^3\\)`} in that every application
                      of {`\\(g_0\\)`} causes the exponent of the exponent of
                      the mod to increment by 1. Therefore, we claim that the
                      coefficients of {`\\(g_0 ∘ g_0 ∘ ... ∘ g_0(t) = g(t)\\)`}{" "}
                      where {`\\(k\\)`} compositions are done.
                    </p>

                    <p>
                      To prove this, we use mathematical induction on{" "}
                      {`\\(k\\)`}:
                    </p>

                    <p>
                      <u>Base Case</u>: {`\\(k = 1, m = 0\\)`}
                    </p>

                    <p>
                      No compositions would be done, so we would require{" "}
                      {`\\(g(t) = t\\)`}. However, when
                    </p>

                    <p>{`$$t\\equiv 0 \\mod 2, g(t) \\equiv 0 \\mod 2^{2^0} \\equiv 0\\mod 2 = t$$`}</p>

                    <p>{`$$t\\equiv 1 \\mod 2, g(t) \\equiv 1 \\mod 2^{2^0} \\equiv 1\\mod 2 = t$$`}</p>

                    <p>Hence the base case is satisfied.</p>

                    <p>
                      <u>Assumptive case</u>: We assume that{" "}
                      {`\\(g(t) = g_0 ∘ ... ∘ g_0 (t)\\)`} for{" "}
                      {`\\(k \\leq 𝑛\\)`} compositions is true.
                    </p>

                    <p>
                      <u>Inductive case</u>: When {`\\(k = 𝑛 + 1\\)`}, we have
                      that from the {`\\((k − 1)\\)`}th composition (by the
                      assumptive step), we would have:
                    </p>

                    <p>{`$$t\\equiv 0 \\mod 2 \\Rightarrow g'(t) \\equiv 0 \\mod 2^{2^{k-1}}$$`}</p>

                    <p>{`$$t\\equiv 1 \\mod 2 \\Rightarrow g'(t) \\equiv 1 \\mod 2^{2^{k-1}}$$`}</p>

                    <p>
                      Therefore, when we perform the {`\\(k\\)`}’th composition,
                      we would then get:
                    </p>

                    <p>{`$$t\\equiv 0 \\mod 2 \\Rightarrow g(t) \\equiv 0\\mod 2^{2^k}$$`}</p>

                    <p>{`$$t\\equiv 1 \\mod 2 \\Rightarrow g(t) \\equiv 1\\mod 2^{2^k}$$`}</p>

                    <p>
                      Therefore, by the inductive hypothesis from the base case,
                      we now have that{" "}
                      {`\\(g_0 ∘ g_0 ∘ ... ∘ g_0 (t) = g(t)\\)`} where{" "}
                      {`\\(k\\)`} compositions are done. As desired.
                    </p>

                    <p>
                      Finally, the onus of this proof is to show that this
                      procedure runs in polynomial time in {`\\(m\\)`}:
                    </p>

                    <p>
                      To do this, we note that each step of the composition
                      involves atleast {`\\(k\\)`} evaluations through
                      multiplication.
                    </p>

                    <p>
                      Therefore, the total number of multiplications would
                      across all the steps would be {`\\(O(2^k) = O(m)\\)`}.
                      Each multiplication runs in{" "}
                      {`\\(𝑝𝑜𝑙𝑦(𝑂(k)) \\in 𝑝𝑜𝑙𝑦(m)\\)`} time. The total time
                      complexity of this procedure is{" "}
                      {`\\(𝑝𝑜𝑙𝑦(m) × 𝑂(m) = 𝑝𝑜𝑙𝑦(m)\\)`}.
                    </p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      Lemma 8: Concluding that{" "}
                      {`\\(\\text{PH} \\subseteq \\text{P}^{\\# \\text{P}}\\)`}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Recall Lemmas {`\\(1\\)`} and {`\\(2\\)`} which state that{" "}
                      {`$$\\text{PH} ⊆ \\text{BPP}^{\\oplus \\text{P}} $$`}{" "}
                      {`$$(\\oplus \\text{P})^{\\oplus \\text{P}} ⊆ \\oplus \\text{P} $$`}
                    </p>

                    <p>
                      <u>Lemma 8.1</u>: any language in{" "}
                      {`\\(\\text{BPP}^{\\oplus \\text{P}}\\)`} can be decided
                      in {`\\(BPP^{\\oplus \\text{P}}\\)`} with the oracle
                      machine making a single query and then immediately
                      entering {`\\(q_\\text{accept}\\)`} or{" "}
                      {`\\(q_\\text{reject}\\)`}.
                    </p>

                    <p>
                      Let {`\\(L \\in \\text{BPP}^{\\oplus \\text{P}}\\)`}. Let{" "}
                      {`\\(M\\)`} be a probabilistic {`\\(TM\\)`} with oracle{" "}
                      {`\\(\\text{P}^{\\oplus \\text{P}}\\)`} that decides a
                      string {`\\(x\\)`} through the following procedure (and
                      only tossing the coins redundantly):
                    </p>

                    <p>
                      {`\\(M(x)\\)`}: Toss the {`\\(c = |x|\\)`} coins and write
                      down the results (what is actually done with the results
                      is not relevant right now).
                    </p>

                    <p>
                      Query the oracle {`\\(\\text{P}^{\\oplus \\text{P}}\\)`}{" "}
                      on {`\\(x\\)`}.
                    </p>

                    <p>
                      If the oracle returns ACCEPT, then enter{" "}
                      {`\\(𝑞_\\text{𝑎cc𝑒𝑝t}\\)`}. If the oracle returns REJECT,
                      then enter {`\\(𝑞_\\text{𝑟𝑒𝑗𝑒ct}\\)`}.
                    </p>

                    <p>
                      Therefore, we have that {`\\(M\\)`} decides {`\\(L\\)`} in{" "}
                      {`\\(\\text{BPP}^{\\text{P}^{\\oplus \\text{P}}}\\)`}.
                      Recall Lemma 2 which states that{" "}
                      {`\\((\\oplus \\text{P})^{\\oplus \\text{P}} ⊆ \\oplus \\text{P}\\)`}
                      . Since {`\\(\\text{P} \\in \\oplus \\text{P}\\)`}, we
                      have that{" "}
                      {`\\(\\text{P}^{\\oplus \\text{P}} ⊆ \\oplus \\text{P}\\)`}
                      . Therefore, {`\\(M\\)`} decides {`\\(L\\)`} in{" "}
                      {`\\(\\text{BPP}^{\\oplus \\text{P}}\\)`}.
                    </p>

                    <p>QED.</p>

                    <p>
                      <u>Lemma 8.2</u>: The outcome of the query is determined
                      by the number of satisfying assignments of some circuit{" "}
                      {`\\(C\\)`}.
                    </p>

                    <p>
                      For the query made by {`\\(M\\)`} to{" "}
                      {`\\(\\oplus \\text{P}\\)`}, we let the turing machine
                      that decides {`\\(\\oplus \\text{P}\\)`} be denoted as{" "}
                      {`\\(M'\\)`}. It follows then that {`\\(M'\\)`} has an
                      equivalent circuit {`\\(C\\)`}. It follows immediately
                      that the outcome of the query (of form{" "}
                      {`\\(q: \\{0, 1\\}^c \\rightarrow \\{0, 1\\}\\)`} (where{" "}
                      {`\\(c\\)`} is the number of coins)) depends on{" "}
                      {`\\(C\\)`}.
                    </p>

                    <p>
                      To determine the relationship further, we recall that{" "}
                      {`\\(\\oplus \\text{P}\\)`} returns ACCEPT if the number
                      of computation paths is odd and REJECT if it is even.
                      Therefore, for an oracle query {`\\(q\\)`} to return
                      ACCEPT, the number of satisfying assignments for{" "}
                      {`\\(C\\)`} would have to be odd (
                      {`\\(\\equiv 1 \\mod 2)\\)`}, and for it to return REJECT,
                      the number of satisfying assignments for {`\\(C\\)`} would
                      have to be even {`\\((\\equiv 0 \\mod 2)\\)`}.
                    </p>

                    <p>
                      Hence, we conclude that the outcome of the query is
                      determined by the number of satisfying assignments of some
                      circuit {`\\(C\\)`}.
                    </p>

                    <p>QED.</p>

                    <p>
                      We now use Lemma {`\\(7\\)`} to produce a polynomial{" "}
                      {`\\(g: \\mathbb{N} → \\mathbb{N}\\)`} such that{" "}
                      {`\\(t \\equiv 0 \\mod 2 \\Rightarrow g(t) \\equiv 0 \\mod 2^m\\)`}{" "}
                      and{" "}
                      {`\\(t \\equiv 1 \\mod 2 \\Rightarrow g(t) \\equiv 1 \\mod 2^m\\)`}
                      , where {`\\(m = c\\)`} (the number of coins used by the
                      turing machine {`\\(M\\)`}).
                    </p>

                    <p>
                      We then use part a (providing circuit {`\\(C\\)`} with{" "}
                      {`\\(s\\)`} satisfying assignments as an input) to produce
                      circuit {`\\(C'\\)`} with {`\\(g(𝑠)\\)`} satisfying
                      assignments). Therefore, {`\\(C'\\)`} would have
                    </p>

                    <p>{`$$0 \\mod 2^c \\text{ satisfying assignments if } C \\text{ had } 0 \\mod 2 \\text{ satisfying assignments}.$$`}</p>

                    <p>{`$$1 \\mod 2^c \\text{ satisfying assignments if } C \\text{ had } 1 \\mod 2 \\text{ satisfying assignments}.$$`}</p>

                    <p>
                      The onus of the proof is to now show that the language can
                      be decided by a deterministic polynomial-time turing
                      machine {`\\(Q\\)`} that uses nondeterministic
                      polynomial-time turing machine oracle {`\\(Q'\\)`} that
                      runs in {`\\(\\#𝑃\\)`}.
                    </p>

                    <p>
                      From lemma {`\\(8.1\\)`}, we have that since the language{" "}
                      {`\\(L\\)`} is decided by {`\\(\\text{BPP}\\)`}, atleast{" "}
                      {`\\(\\frac{2}{3}\\)`} of the computation paths correctly
                      produce circuit {`\\(C'\\)`} when {`\\(x \\in L\\)`} such
                      that {`\\(C'\\)`} has {`\\(1 \\mod 2^c\\)`} satisfying
                      assignments. Similarly, atmost {`\\(\\frac{1}{3}\\)`} of
                      the computation paths correctly produce circuit{" "}
                      {`\\(C'\\)`} when {`\\(x \\notin L\\)`} such that{" "}
                      {`\\(C'\\)`} has {`\\(0 \\mod 2^c\\)`} satisfying
                      assignments. Therefore, the total number of paths that
                      produce {`\\(C'\\)`} with {`\\(1 \\mod 2^c\\)`} satisfying
                      assignments when {`\\(x \\in L\\)`} is{" "}
                      {`\\(\\geq \\frac{2}{3}\\cdot 2^c\\)`}, whereas the total
                      number of paths that produce {`\\(C'\\)`} with{" "}
                      {`\\(0 \\mod 2^c\\)`} satisfying assignments when{" "}
                      {`\\(x \\notin L\\)`} is{" "}
                      {`\\(\\leq \\frac{1}{3} \\cdot 2^c\\)`}.
                    </p>

                    <p>
                      Note then that we can use a {`\\(\\#P\\)`} oracle to find
                      the total length of the set{" "}
                      {`\\(𝑆 = (x = \\{0,1\\}^c) × i\\)`} such that{" "}
                      {`\\(C'(𝑞(x), i) = 1\\)`} where {`\\(x\\)`} is every
                      possible input of length {`\\(c\\)`} in the set,{" "}
                      {`\\(𝑞(x)\\)`} is a result of querying {`\\(x\\)`}, and{" "}
                      {`\\(i\\)`} is the {`\\(d\\)`}-dimensional input to{" "}
                      {`\\(C'\\)`}.
                    </p>

                    <p>
                      Then, a polynomial procedure {`\\(H\\)`} does the
                      following:
                    </p>

                    <p>
                      Use the method described in the previous paragraph to
                      query an oracle to find the length of the set, |𝑆|.
                      Calculate {`\\(k = |𝑆| \\mod 2^c\\)`}.
                    </p>

                    <p>
                      If {`\\(k \\geq \\frac{2}{3} \\cdot 2^c\\)`}, then return
                      ACCEPT.
                    </p>

                    <p>
                      If {`\\(k \\leq \\frac{2}{3} \\cdot 2^c\\)`}, then return
                      REJECT.
                    </p>

                    <p>
                      There are atleast {`\\(\\frac{2}{3}\\)`} accepting paths
                      that we explicitly search for and atmost{" "}
                      {`\\(\\frac{1}{3}\\)`} rejecting paths that we explicitly
                      search for by using {`\\(\\#\\text{P}\\)`} which is then
                      verified through the modulo operation by procedure{" "}
                      {`\\(H\\)`}. Hence, we now have that any for an arbitrary
                      language {`\\(L \\in \\text{BPP}^{\\oplus \\text{P}}\\)`},{" "}
                      {`\\(L \\in \\text{P}^{\\#\\text{P}}\\)`}. Therefore, we
                      now have that:
                    </p>

                    <p>{`$$\\text{BPP}^{\\oplus \\text{P}} ⊆ \\text{P}^{\\#\\text{P}}$$`}</p>

                    <p>However, recall result 1 from the previous set that:</p>

                    <p>{`$$\\text{PH} ⊆ \\text{BPP}^{\\oplus \\text{P}}$$`}</p>

                    <p>
                      Therefore, by the property of transitivity we now have
                      that
                    </p>

                    <p>{`$$\\text{PH} ⊆ \\text{P}^{\\#\\text{P}}$$`}</p>

                    <p>QED.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </StylesDiv>
          </ResponsiveContainer>
          <GlobalFooter />
        </>
      </StyledArticle>
      <ScrollRestoration />
    </>
  );
};

export default TodaTheorem;
